export const StorageItem = Object.freeze({
    User: 'CT_Bikes/user',
    LoggedInUser : 'CT_Bikes/loggedInUser',
    JwtToken : 'CT_Bikes/jwtToken',
    RefreshToken : 'CT_Bikes/refreshToken',
  });
  
  export const getItem = (itemName) => {
    const item = localStorage.getItem(itemName);
    return item ? JSON.parse(item) : null;
  };
  
  export const setItem = (itemName, value) => {
    localStorage.setItem(itemName, JSON.stringify(value));
  };
  
  export const setSessionItem = (itemName, value) => {
    sessionStorage.setItem(itemName, JSON.stringify(value));
  };
  
  export const getSessionItem = (itemName) => {
    const item = sessionStorage.getItem(itemName);
    return item ? JSON.parse(item) : null;
  };
  
  
  export const removeItem = (itemName) => {
    localStorage.removeItem(itemName);
    sessionStorage.removeItem(itemName);
  };
  