import React from 'react'

function Paginate({ totalRecords, recordsPerPage, onPageChange }) {
    const [currentPage, setCurrentPage] = React.useState(1);
    const pages = [];
    const totalPages = Math.ceil(totalRecords / recordsPerPage);
    for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
    }
    const handleClick = page => {
        setCurrentPage(page);
        onPageChange(page);
    };
    return (

        <div className='flex justify-end'>
        <div>
            {currentPage > 1 && (
                <button
                    onClick={() => handleClick(currentPage - 1)}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded mx-4"
                >
                    Prev
                </button>
            )}

            {currentPage > 3 && (
                <button
                    onClick={() => handleClick(1)}
                    className=" bg-gray-500 hover:bg-gray-700 text-white font-semibold py-2 px-4 rounded mx-2"
                >
                    1
                </button>
            )}

            {currentPage > 4 && <span className="mx-2">......</span>}

            {pages.map(page => {
                if (page === currentPage) {
                    return (
                        <button
                            key={page}
                            onClick={() => handleClick(page)}
                            className="bg-gray-500 hover:bg-gray-700  text-white font-semibold py-2 px-4 rounded mx-2"
                        >
                            {page}
                        </button>
                    );
                } else if (page >= currentPage - 1 && page <= currentPage + 1) {
                    return (
                        <button
                            key={page}
                            onClick={() => handleClick(page)}
                            className="bg-gray-500 hover:bg-gray-700  text-white font-semibold py-2 px-4 rounded mx-2"
                        >
                            {page}
                        </button>
                    );
                } else {
                    return null;
                }
            })}

            {currentPage < pages.length - 3 && <span className="mx-1">......</span>}

            {currentPage < pages.length - 2 && (
                <button
                    onClick={() => handleClick(pages.length)}
                    className="bg-gray-500 hover:bg-gray-700  text-white font-semibold py-2 px-4 rounded mx-2"
                >
                    {pages.length}
                </button>
            )}

            {currentPage < pages.length && (
                <button
                    onClick={() => handleClick(currentPage + 1)}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded mx-4"
                >
                    Next
                </button>
            )}
        </div>
        </div>
    )
}

export default Paginate