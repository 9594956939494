import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import { Alert, AlertIcon, Box, Center, ChakraProvider, Spinner, Stack } from "@chakra-ui/react";
import { JWTInterceptor } from "Api_Handling/jwt-interceptor";
import { ServerErrorInterceptor } from "Api_Handling/server-error-interceptor";
import { StorageItem } from "Api_Handling/local-storage.utils";
import { getItem } from "Api_Handling/local-storage.utils";
import { getSessionItem } from "Api_Handling/local-storage.utils";
import { useDispatch, useSelector } from "react-redux";
import { hideUpdateAlert } from "store/globalState";
import { hideCreateAlert } from "store/globalState";
import { hideDeleteAlert } from "store/globalState";


JWTInterceptor()
ServerErrorInterceptor()

const App = () => {
  const dispatch = useDispatch();
  const Loading = useSelector((state) => state.global_state.isLoading)
  const updateAlert = useSelector((state) => state.global_state.updateAlert)
  const createAlert = useSelector((state) => state.global_state.createAlert)
  const deleteAlert = useSelector((state) => state.global_state.deleteAlert)
  const token = getItem(StorageItem.JwtToken) || getSessionItem(StorageItem.JwtToken);

  const closeUpdateAlert = () => {
    dispatch(hideUpdateAlert());
  };

  if (updateAlert) {
    setTimeout(() => {
      closeUpdateAlert();
    }, 4000); // Close the alert after 4 seconds (4000 milliseconds)
  }
  const closeCreateAlert = () => {
    dispatch(hideCreateAlert());
  };

  if (createAlert) {
    setTimeout(() => {
      closeCreateAlert();
    }, 4000); // Close the alert after 4 seconds (4000 milliseconds)
  }
  const closeDeleteAlert = () => {
    dispatch(hideDeleteAlert());
  };

  if (deleteAlert) {
    setTimeout(() => {
      closeDeleteAlert();
    }, 6000); // Close the alert after 4 seconds (4000 milliseconds)
  }
  return (
    <ChakraProvider>
      <Routes>

        {
          token ? (
            <>
              <Route path="admin/*" element={<AdminLayout />} />
              <Route path="/" element={<Navigate to="/admin/default" replace />} />
            </>
          )
            :
            (
              <>
                <Route path="auth/*" element={<AuthLayout />} />
                <Route path="/" element={<Navigate to="/auth/sign-in" replace />} />
              </>
            )

            

        }
         <Route path="/auth/sign-in" element={<AuthLayout />} />


      </Routes>
      {
        updateAlert &&
        <Stack
          spacing={3}
          position="fixed"
          bottom={0}
          left={0}
          marginLeft={3} // Adjust this value as needed
          marginBottom={3} // Adjust this value as needed
        >
          <Alert status="success" onClose={closeUpdateAlert}>
            <AlertIcon />
            Updated Successfully!!!
          </Alert>
        </Stack>

      }
          {
        createAlert &&
        <Stack
          spacing={3}
          position="fixed"
          bottom={0}
          left={0}
          marginLeft={3} // Adjust this value as needed
          marginBottom={3} // Adjust this value as needed
        >
          <Alert status="success" onClose={closeCreateAlert}>
            <AlertIcon />
            Created Successfully!!!
          </Alert>
        </Stack>

      }
      {
        deleteAlert &&
        <Stack
          spacing={3}
          position="fixed"
          bottom={0}
          left={0}
          marginLeft={3} // Adjust this value as needed
          marginBottom={3} // Adjust this value as needed
        >
          <Alert status="success" onClose={closeDeleteAlert}>
            <AlertIcon />
            Deleted Successfully!!!
          </Alert>
        </Stack>

      }
      {
        Loading && (
          <Center
            position="fixed" // Set position to fixed
            top="0"
            left="0"
            right="0"
            bottom="0"
            zIndex="10000"
            bg="rgba(0, 0, 0, 0.50)" // Background color
          >
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
          </Center>
        )}



    </ChakraProvider>
  );
};

export default App;
