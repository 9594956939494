import React, { useEffect } from 'react'
import ReviewsTable from 'views/admin/tables/components/ReviewsTable'
import { ReviewsColData } from 'views/admin/tables/variables/columnsData'
import { useDispatch, useSelector } from 'react-redux';
import { fetchProdReviews } from 'store/globalState';
import Paginate from 'components/Pagination/Paginate';

function Reviews() {
    const reviews = useSelector((state) => state.global_state.reviews?.newAllProducts)
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(fetchProdReviews())
    },[dispatch])
    const [currentPage, setCurrentPage] = React.useState(1);
    const recordsPerPage = 5; // Set the number of records per page
    const handlePageChange = (page) => {
      setCurrentPage(page);
    };
    const currentData = reviews?.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage);
  return (
    <div className="mt-[2.25rem] h-full w-full">
    <ReviewsTable 
        columnsData={ReviewsColData}
        tableData={currentData}
    />
     <Paginate totalRecords={reviews?.length} recordsPerPage={recordsPerPage} onPageChange={handlePageChange} />
    </div>
  )
}

export default Reviews