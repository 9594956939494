/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import InputField from 'components/fields/InputField'
import React, { useEffect, useMemo, useState } from 'react'
import { FormControl, FormLabel, Checkbox } from '@chakra-ui/react'
import { Select } from '@chakra-ui/react'
import { ImCross } from 'react-icons/im'
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertFromRaw , convertToRaw  } from 'draft-js';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchProductEdit } from 'store/globalState'
import { fetchCategories, uploadNewImage } from 'store/globalState'
import { uploadMultipleImages } from 'store/globalState'
import { UpdateProduct } from 'store/globalState'
import { fetchProductList } from 'store/globalState'


function EditProduct() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const productEditData = useSelector((state) => state.global_state.productEdit?.product) || {};
    const categories = useSelector((state) => state.global_state.categories?.categories) || [];
    const productImages = useSelector((state) => state.global_state.productImages) || [];
    const mainImageGlobal = useSelector((state) => state.global_state.mainImageUrl);
    const [images, setImages] = useState([]);
    const [subCatCheck, setSubCatCheck] = useState(false);
    const [subCategories, setSubCategories] = useState([]);
    const [mainCategories, setMainCategories] = useState([]);
    const [main_image , setMainImage] = useState('');
    const [editData, setEditData] = useState(productEditData || "");
    const navigate = useNavigate();
   

    let initialContent = {
        entityMap: {},
        blocks: [
            {
                key: '1',
                text: productEditData?.description || "",
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
                data: {}
            }
        ]
    };
    const [editorState, setEditorState] = useState(() => {
        const contentState = convertFromRaw(initialContent);
        return EditorState.createWithContent(contentState);
    });

    useMemo(() => {
        dispatch(fetchProductEdit(id))
    }, [dispatch, id]);

    useEffect(() => {
        if (productEditData) {
            const contentState = convertFromRaw(initialContent);
            setEditorState(EditorState.createWithContent(contentState));
            setImages(productEditData?.images);
            setMainImage(productEditData?.main_image?.url)
            setEditData(productEditData)
        }

    }, [productEditData, productEditData.description])

    useEffect(() => {
        if(categories?.length > 0 && productEditData) {
            const subCats = categories?.map(value => {
                if(value?.parent_category === productEditData?.parent_category?.name) {
                  
                    return value?.category
                }
                return null
            }).filter(val => val)
            setSubCategories(...subCats)
            setMainCategories(categories)
            }
    }, [categories, productEditData])

    useEffect(() => {
        if(mainImageGlobal) {
            setMainImage(mainImageGlobal)
            setEditData({...productEditData , main_image: {url : mainImageGlobal}})
        }
    }, [mainImageGlobal])

    useEffect(() => {
        dispatch(fetchCategories())
    },[dispatch])

    const EditorStateChange = (editorState) => {
        setEditorState(editorState);
        setEditData({
            ...editData,
            description: convertToRaw(editorState.getCurrentContent())?.blocks[0]?.text
        })
    };
       

    const removeImage = (i) => 
    {
        let copyImg = [...images];
        copyImg.splice(i,1);
        setImages(copyImg);
        setEditData(Object.assign(editData, {images: copyImg} ))
    }

    const addNewImage = (event) => {
        const file = event?.target?.files[0];
        if(file) {
            const formData = new FormData();
            formData.append('image', file);
            dispatch(uploadNewImage(formData))
        }
    }

    const onSelectCategory = (event) => {
        const subCats = mainCategories?.map(value => {
            if(value?._id === event?.target?.value) {
                return value?.category
            }
            return null
        }).filter(val => val)
        setSubCatCheck(true);
        setSubCategories(...subCats)
        setEditData({
            ...editData,
            [event.target.name]: event.target.value

        })
    }

    const subCatChange = (event) => 
    {
        setEditData({
            ...editData,
            [event.target.name]: event.target.value
        })
    }
    
    const addProductImages = (event) => {
        const files = event?.target?.files;
        if(files?.length > 0) {
            const formData = new FormData();
            Array.from(files).forEach(value => {
                formData.append('images', value)
            })
            dispatch(uploadMultipleImages(formData))
        }
    }

    useEffect(() => {
        if(productImages?.length > 0) {
            setImages([...images, ...productImages]);
            setEditData({...productEditData ,  images: [...images, ...productImages] })
        }
    }, [productImages])

    const handleChange = (event) => 
    {
        setEditData({
            ...editData,
            [event.target.name]: event.target.value
        })
    }
    const updateData = async () => {
        let editDataCopy = {...editData};
        if (editDataCopy._id){
            editDataCopy._id = undefined;
        }
        if(typeof(editDataCopy.parent_category) !== "string")
        {
            editDataCopy.parent_category = editDataCopy.parent_category._id
        }
        delete editDataCopy.slug;
        delete editDataCopy.quantity;
        delete editDataCopy.average_rating;
        delete editDataCopy.reviews;
        if(editDataCopy && editData)
        {
           await dispatch(UpdateProduct({data: editDataCopy, id: editData._id}))
           await Navigate()
        }
    
    }
    const featuredChecked = (event) => {
        setEditData({
            ...editData,
            [event.target.name]: event.target.checked
        })
    }

    const cancelBtn = (event) => {
        event.preventDefault();
        navigate('/admin/products')
      }
      const Navigate = async () => {
        navigate('/admin/products')
        await dispatch(fetchProductList());
    }
    return (
        <div className="mt-[2.25rem] h-full w-full">
            <div className='grid grid-cols-2 gap-4 w-full'>
                <InputField
                    variant="auth"
                    extra="mb-3 w-full pr-2"
                    label="Title*"
                    placeholder="Enter Title.."
                    id="title"
                    type="text"
                    name = "title"
                    value={editData?.title || ""}
                    onChange={handleChange}
                />
                <FormControl className='w-full'>
                    <FormLabel htmlFor="categories" fontSize="sm" fontWeight="medium" className='ml-1'>Main Category</FormLabel>
                    <Select onChange={(event) => onSelectCategory(event)} name='parent_category' id="categories" className='w-full mt-0.5' height='49px' borderRadius='12px'>
                        {
                            mainCategories?.map((mcategory, index) => {
                                {
                                    return <option selected={mcategory?._id === productEditData?.parent_category?._id} value={mcategory?._id} key={index}>{mcategory?.parent_category}</option>
                                }
                            })
                        }                 
                    </Select>
                </FormControl>
            </div>
            <div className='grid grid-cols-2 gap-4 w-full'>
                <FormControl className='w-full'>
                    <FormLabel htmlFor="categories" fontSize="sm" fontWeight="medium" className='ml-1'>Sub Category</FormLabel>
                    <Select id="categories" onChange={subCatChange} name='category' className='w-full mt-0.5' height='49px' borderRadius='12px'>
                        {subCategories?.map((value, index) => {
                            return (index === 0 && subCatCheck === false ?
                                <option key={index} value={productEditData?.category}>{productEditData?.category}</option>
                                :
                                <option key={index} value={value?.name}>{value?.name}</option>
                            )
                        })}
                    </Select>
                </FormControl>
                <InputField
                    variant="auth"
                    extra="mb-3 w-full"
                    label="Price*"
                    placeholder="Enter Price.."
                    id="price"
                    name="price"
                    type="number"
                    value={editData?.price || ""}
                    onChange={handleChange}
                />


            </div>
            <div className='grid grid-cols-2 gap-4 w-full'>

                <InputField
                    variant="auth"
                    extra="mb-3 w-full"
                    label="Discount Price*"
                    placeholder="Enter Discount Price.."
                    id="discount price"
                    type="text"
                    name="comparePrice"
                    value={editData?.comparePrice || ""}
                    onChange={handleChange}
                />
                <InputField
                    variant="auth"
                    extra="mb-3 w-full"
                    label="Stock*"
                    placeholder="Enter Stock.."
                    id="stock"
                    type="text"
                    name="quantity_in_stock"
                    value={editData?.quantity_in_stock || ""}
                    onChange={handleChange}
                />
            </div>
            <div className='w-full mb-5'>
                <Checkbox defaultChecked={editData.featured} name='featured' size='lg' colorScheme='green' className='mt-5 w-full' onChange={featuredChecked}>
                    Feature this Product?
                </Checkbox>
            </div>
            <div className='grid grid-cols-12 gap-4'>
                <div className="col-span-12">
                    <label
                        htmlFor=""
                        className={`text-sm text-navy-700 dark:text-white ml-1.5 font-medium block mb-3`}
                    >
                        Description*
                    </label>
                    <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="border border-gray-400 rounded-lg"
                        editorClassName="px-3"
                        onEditorStateChange={EditorStateChange}
                        toolbar={{
                            options: [ 'inline', 'link', 'list']
                        }}
                    />
                </div>
            </div>
            <div className=' mt-5'>
                <div className="col-span-6">
                    <label
                        htmlFor=""
                        className={`text-sm text-navy-700 dark:text-white ml-1.5 font-medium`}
                    >
                        Cover Image*
                    </label>
                    {
                        main_image ?
                            <div onChange={addNewImage} class="flex items-center justify-center w-full mt-3">
                                <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                    <div class="flex flex-col items-center justify-center w-full h-full">
                                        <img src={main_image} alt="" className='w-full object-contain h-full'/>
                                    </div>
                                    <input id="dropzone-file" type="file" class="hidden" accept="image/png, image/jpeg" crossOrigin='anonymous'/>
                                </label>
                            </div>
                            :
                            <div class="flex items-center justify-center w-full mt-3">
                                <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                    <div class="flex flex-col items-center justify-center w-full h-full">
                                        <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                                        <p class="text-xs text-gray-500 dark:text-gray-400">PNG or JPG</p>
                                    </div>
                                    <input id="dropzone-file" type="file" class="hidden" accept="image/png, image/jpeg"/>
                                </label>
                            </div>
                    }

                </div>
            </div>
            <div className='mt-5'>
                <label
                    htmlFor=""
                    className={`text-sm text-navy-700 dark:text-white ml-1.5 font-medium`}
                >
                    Product Images*
                </label>

                <div onChange={addProductImages} class="flex items-center justify-center w-full mt-3">
                    <label for="dropzone-files" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                       
                    <div class="flex gap-4 w-full h-full mt-3 ml-3" >
                        {
                            images?.map((img, index) => {
                                return <div className='relative' key={index}>
                                            <img src={img?.url} alt="" className='w-24 object-contain h-24 rounded-lg'/>
                                            <div className=' absolute top-0 -right-1 rounded-full p-1 bg-red-600'>
                                                <ImCross className='text-[10px]  text-white'  onClick={(event) => {event.preventDefault(); removeImage(index)}}/>
                                            </div>
                                        </div>
                            })
                          
                        }
                        </div>
                        <input id="dropzone-files" type="file" class="hidden" multiple/>
                    </label>
                </div>
            </div>
            <div className="flex justify-end mt-5">
                <div className="relative">
                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 mr-2 rounded" onClick={updateData}>
                        Update
                    </button>
                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={cancelBtn}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}

export default EditProduct