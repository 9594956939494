import React, { useEffect, useState } from 'react'
import InputField from 'components/fields/InputField'
import { uploadNewImage } from 'store/globalState'
import { useDispatch, useSelector } from 'react-redux'
import { fetchGeneralSetting } from 'store/globalState'
import { UpdateGeneralSetting } from 'store/globalState'
import { uploadSettingImg } from 'store/globalState'



function Setting() {
    const dispatch = useDispatch();
    const mainImageGlobal = useSelector((state) => state.global_state.mainImageUrl);
    const settingImg = useSelector((state) => state.global_state.settingImg);
    const generalSetting = useSelector((state) => state.global_state.generalSetting?.generalSetting);
    const [siteLogo , setSiteLogo] = useState('');
    const [favIcon , setFavIcon] = useState('');
   const [editData, setEditData] = useState(generalSetting || "");
   const addNewImage = (event) => {
       const file = event?.target?.files[0];
       if(file) {
           const formData = new FormData();
           formData.append('image', file);
           dispatch(uploadNewImage(formData))
       }
   }
   const addNewfavImage = (event) => {
       const file = event?.target?.files[0];
       if(file) {
           const formData = new FormData();
           formData.append('image', file);
           dispatch(uploadSettingImg(formData))
       }
   }
   useEffect(() => {
       dispatch(fetchGeneralSetting())
   },[dispatch])
   useEffect(() => {
       if (generalSetting) {
           setSiteLogo(generalSetting?.site_logo?.url)
           setFavIcon(generalSetting?.favicon?.url)
           setEditData(generalSetting)
       }

   }, [generalSetting])

   useEffect(() => {
       if(mainImageGlobal) {
           setSiteLogo(mainImageGlobal)
           setEditData({...generalSetting , siteLogo: {url : mainImageGlobal}})
       }
 
   }, [mainImageGlobal])
   useEffect(() => {
       if(settingImg) {
           setFavIcon(settingImg)
           setEditData({...generalSetting , favIcon: {url : settingImg}})
       }
 
   }, [settingImg])

   const handleChange = (event) => 
   {
       setEditData({
           ...editData,
           [event.target.name]: event.target.value
       })
   }
   const updateData = () => {
       dispatch(UpdateGeneralSetting(editData))
   }

    return(
     <>
        <div className='grid grid-cols-2 gap-4 w-full'>
        <div class="w-full mt-3">
            <label
                htmlFor=""
                className={`text-sm text-navy-700 dark:text-white ml-1.5 font-medium`}
            >
                Site Logo*
            </label>

            {
                siteLogo ? 
                <label onChange={addNewImage}  for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                <div class="flex flex-col items-center justify-center w-full h-full">
                    {
                          <img src={siteLogo} alt="" className='w-full object-contain h-full' crossOrigin='anonymous'/>
                    }
                </div>
                <input id="dropzone-file" type="file" class="hidden" accept="image/png, image/jpeg" />
            </label>
            :
            <label  for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
            <div class="flex flex-col items-center justify-center w-full h-full">
                {
                      <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                      <div class="flex flex-col items-center justify-center w-full h-full">
                          <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                          <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                          <p class="text-xs text-gray-500 dark:text-gray-400">PNG or JPG</p>
                      </div>
                      <input id="dropzone-file" type="file" class="hidden" accept="image/png, image/jpeg"/>
                  </label>
                }
            </div>
            <input id="dropzone-file" type="file" class="hidden" accept="image/png, image/jpeg" />
        </label>
            }
          
        </div>
        <div class="w-full mt-3">
            <label
                htmlFor=""
                className={`text-sm text-navy-700 dark:text-white ml-1.5 font-medium`}
            >
                Favicon*
            </label>
            {
                favIcon ? 
                <label onChange={addNewfavImage}  for="dropzone-files" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                <div class="flex flex-col items-center justify-center w-full h-full">
                    {
                          <img src={favIcon} alt="" className='w-full object-contain h-full' crossOrigin='anonymous'/>
                    }
                </div>
                <input id="dropzone-files" type="file" class="hidden" accept="image/png, image/jpeg" />
            </label>
            :
            <label  for="dropzone-files" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
            <div class="flex flex-col items-center justify-center w-full h-full">
                {
                      <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                      <div class="flex flex-col items-center justify-center w-full h-full">
                          <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                          <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                          <p class="text-xs text-gray-500 dark:text-gray-400">PNG or JPG</p>
                      </div>
                      <input id="dropzone-files" type="file" class="hidden" accept="image/png, image/jpeg"/>
                  </label>
                }
            </div>
            <input id="dropzone-files" type="file" class="hidden" accept="image/png, image/jpeg" />
        </label>
            }
          
        </div>
        <InputField
            variant="auth"
            extra="mb-3 w-full pr-2"
            label="Site Name*"
            placeholder="Enter Site Name.."
            id="site_name"
            type="text"
            name="site_name"
        value={editData?.site_name || ""}
        onChange={handleChange}
        />
    </div>
    <div className="flex justify-end mt-7">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={updateData}>
                Update
            </button>
        </div>
        </>
    )
}

export default Setting