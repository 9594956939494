import Paginate from 'components/Pagination/Paginate';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrderList } from 'store/globalState';
import ComplexTable from 'views/admin/tables/components/OrderListingTable'
import { columnsDataComplex } from 'views/admin/tables/variables/columnsData'


function Order() {
  let pageNumber = 1;
  let pageSize = 3;
  const dispatch = useDispatch();
   
  const orderListData = useSelector((state) => state.global_state.orderList?.order);
  useEffect(() =>
  {
    dispatch(fetchOrderList())
  },[dispatch, pageNumber, pageSize])
  const [currentPage, setCurrentPage] = React.useState(1);
  const recordsPerPage = 5; // Set the number of records per page
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const currentData = orderListData?.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage);


  return (
    <div className="mt-[2.25rem] h-full w-full">
      <ComplexTable
        columnsData={columnsDataComplex}
        tableData={currentData}
      />
      <Paginate totalRecords={orderListData?.length} recordsPerPage={recordsPerPage} onPageChange={handlePageChange} />
    </div>
  )
}

export default Order