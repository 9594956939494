export const columnsDataDevelopment = [
  {
    Header: "Image",
    accessor: "image",
  },
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "MAIN CATEGORY",
    accessor: "parent_category",
  },
  {
    Header: "SUB CATEGORY",
    accessor: "category",
  },
  {
    Header: "Price",
    accessor: "price",
  },
  {
    Header: "Discount Price",
    accessor: "comparePrice",
  },
  {
    Header: "Stock",
    accessor: "quantity_in_stock",
  }
 
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataComplex = [
  {
    Header: "NAME",
    accessor: "first_name",
  },
  {
    Header: "PHONE",
    accessor: "phone_number",
  },
  {
    Header: "ADDRESS",
    accessor: "email_address",
  },
  {
    Header: "TOTAL PRICE",
    accessor: "subtotal",
  },
  {
    Header: "STATUS",
    accessor: "isPaid",
  },
  {
    Header: "DATE",
    accessor: "createdAt",
  }
];

export const OrderListColData = [
  {
    Header: "IMAGE",
    accessor: "main_image.url",
  },
  {
    Header: "TITLE",
    accessor: "title",
  },
  {
    Header: "PRICE",
    accessor: "price",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "ITEM SUBTOTAL",
    accessor: "item_subtotal",
  }
  ,
  {
    Header: "INSTOCK",
    accessor: "quantity_in_stock",
  }
];

export const ReviewsColData = [
  {
    Header: "IMAGE",
    accessor: "image",
  },
  {
    Header: "TITLE",
    accessor: "title",
  },
  {
    Header: "CUSTOMER",
    accessor: "customer",
  },
  {
    Header: "RATING",
    accessor: "rating",
  },
  {
    Header: "DATE",
    accessor: "date",
  }
];

export const CouponColData = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "CODE",
    accessor: "code",
  },
  {
    Header: "DISCOUNT",
    accessor: "discount",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "START",
    accessor: "createdAt",
  },
  {
    Header: "END",
    accessor: "expirationTime",
  },
  {
    Header: "USED",
    accessor: "usersUsed",
  }
];

export const SocialMediaColData = [
  {
    Header: "IMAGE",
    accessor: "image.url",
  },
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "URL",
    accessor: "url",
  }
];

export const SliderColData = [
  {
    Header: "IMAGE",
    accessor: "image.url",
  },
  {
    Header: "HEADING",
    accessor: "text_two",
  },
  {
    Header: "SUBHEADING",
    accessor: "text_one",
  }
];

