import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { editSocialMedia } from 'store/globalState';
import { fetchSocialMedia } from 'store/globalState';
import SocialMediaTable from 'views/admin/tables/components/SocialMediaTable'
import { SocialMediaColData } from 'views/admin/tables/variables/columnsData'


function SocialMedia() {
    const dispatch = useDispatch();
    const socialMedia = useSelector((state) => state.global_state.socialMedia?.socialMedia)
    useEffect(() => {
        dispatch(fetchSocialMedia());
    },[])
    return(
        <div className='mt-[2.25rem] h-full w-full'>
        <SocialMediaTable
            columnsData = {SocialMediaColData}
            tableData={socialMedia}
        />
         
    </div>
        
    )
}

export default SocialMedia