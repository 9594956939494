import { configureStore } from "@reduxjs/toolkit";
import globalState from "./globalState";

const store = configureStore({

reducer: {
    global_state: globalState.reducer,
},
});


export default store;