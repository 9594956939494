import React, { useEffect } from 'react'
import DevelopmentTable from 'views/admin/tables/components/ProductListingTable'
import { columnsDataDevelopment } from 'views/admin/tables/variables/columnsData';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductList } from 'store/globalState';
import Paginate from 'components/Pagination/Paginate';

function Product() {
  const dispatch = useDispatch();
  const productListData = useSelector((state) => state.global_state.productList?.products)
  useEffect(() => 
  {
    dispatch(fetchProductList());
  },[dispatch])

  const [currentPage, setCurrentPage] = React.useState(1);
  const recordsPerPage = 5; // Set the number of records per page
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const currentData = productListData?.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage);
  return (
    <div className="mt-[2.25rem] h-full w-full">
    <DevelopmentTable
      columnsData={columnsDataDevelopment}
      tableData={currentData}
    />
    <Paginate totalRecords={productListData?.length} recordsPerPage={recordsPerPage} onPageChange={handlePageChange} />
  </div>
  )
}

export default Product