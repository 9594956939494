import React, { useEffect, useState } from 'react'
import InputField from 'components/fields/InputField'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAppStore } from 'store/globalState';
import { UpdateAppStore } from 'store/globalState';

function AppStore() {
    const dispatch = useDispatch();
    const appStore = useSelector((state) => state.global_state.appStore?.appstore);
    const [editData, setEditData] = useState(appStore || "");
    const paymentImage = useSelector((state) => state.global_state.paymentImg);
    const [paymentImg , setPaymentImg] = useState('');
    useEffect(() => {
        dispatch(fetchAppStore())
    },[dispatch])

    const PaymentImg = (event) => {
        const file = event?.target?.files[0];
        if(file) {
            const formData = new FormData();
            formData.append('image', file);
            dispatch(fetchAppStore(formData))
        }
    }
    useEffect(() => {
        if (appStore) {
            setPaymentImg(appStore?.image?.url)
            setEditData(appStore)
        }

    }, [appStore])

    useEffect(() => {
        if(paymentImage) {
            setPaymentImg(paymentImage)
            setEditData({...appStore , paymentImg: {url : paymentImage}})
        }
    }, [paymentImage])

    const handleChange = (event) => 
    {
        setEditData({
            ...editData,
            [event.target.name]: event.target.value
        })
    }
    const updateData = () => {
        dispatch(UpdateAppStore(editData))
    }

    return(
        <div className='w-full h-full'>
        <div class="w-full mt-3">
                <label
                    htmlFor=""
                    className={`text-sm text-navy-700 dark:text-white ml-1.5 font-medium`}
                >
                    Payment Img*
                </label>
               
                {
                                    paymentImg ? 
                                    <label onChange={PaymentImg}  for="dropzone-filess" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                    <div class="flex flex-col items-center justify-center w-full h-full">
                                        {
                                              <img src={paymentImg} alt="" className='w-full object-contain h-full' crossOrigin='anonymous'/>
                                        }
                                    </div>
                                    <input id="dropzone-filess" type="file" class="hidden" accept="image/png, image/jpeg" />
                                </label>
                                :
                                <label  for="dropzone-filess" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                <div class="flex flex-col items-center justify-center w-full h-full">
                                    {
                                          <label for="dropzone-filess" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                          <div class="flex flex-col items-center justify-center w-full h-full">
                                              <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                              <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                                              <p class="text-xs text-gray-500 dark:text-gray-400">PNG or JPG</p>
                                          </div>
                                          <input id="dropzone-filess" type="file" class="hidden" accept="image/png, image/jpeg"/>
                                      </label>
                                    }
                                </div>
                                <input id="dropzone-file" type="file" class="hidden" accept="image/png, image/jpeg" />
                            </label>
                                }
            </div>
        <div className='grid grid-cols-2 gap-4 w-full mt-5'>
        <InputField
                variant="auth"
                extra="mb-3 w-full pr-2"
                label="Address*"
                placeholder="Enter address .."
                id="address"
                type="text"
                name="address"
            value={editData?.address || ""}
            onChange={handleChange}
            />
              <InputField
                variant="auth"
                extra="mb-3 w-full pr-2"
                label="Email*"
                placeholder="Enter Email.."
                id="email"
                type="text"
                name="email"
            value={editData?.email || ""}
            onChange={handleChange}
            />
              <InputField
                variant="auth"
                extra="mb-3 w-full pr-2"
                label="Phone*"
                placeholder="Enter phone Name.."
                id="phone"
                type="text"
                name="phone"
            value={editData?.phone || ""}
            onChange={handleChange}
            />
        </div>
        <div className="flex justify-end mt-7">
                                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={updateData}>
                                    Update
                                </button>
                            </div>
        </div>
    )
}

export default AppStore