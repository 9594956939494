
import React, { useEffect, useState } from 'react'
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import AppStore from './AppStore'
import SocialMedia from './SocialMedia'
import Setting from './Setting'
import Slider from './Slider'

function GeneralSetting() {
  
    return (
        <div className=' mt-[2.25rem] h-full w-full'>
            <Tabs>
                <TabList>
                    <Tab>General Setting</Tab>
                    <Tab>App Store</Tab>
                    <Tab>Social Media</Tab>
                    <Tab>Slider</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                    <Setting />
                    </TabPanel>
                    <TabPanel>
                        <AppStore />
                    </TabPanel>
                    <TabPanel>
                        <SocialMedia />
                    </TabPanel>
                    <TabPanel>
                        <Slider />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </div>
    )
}

export default GeneralSetting