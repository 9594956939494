
import Card from "components/card";
import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";
import { useMemo } from "react";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { editSocialMedia } from "store/globalState";


const SliderTable = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { columnsData, tableData } = props;
    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData || [], [tableData]);
    const tableInstance = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        initialState,
    } = tableInstance;
    initialState.pageSize = 5;

    const editProduct = (id) => {
        navigate(`/admin/edit-slider/${id}`)
        dispatch(editSocialMedia(id))
       
      }
      const deleteProduct = (id) => {
        // dispatch(deleteProd(id))
        // dispatch(fetchProductList());
      }
      const addSlider = () => {
        navigate(`/admin/create-slider`)
    }
    return (
        <Card extra={"w-full h-full p-4 sm:overflow-x-auto"}>
               <div className="relative flex items-center justify-end">
                <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-4 rounded-md" onClick={addSlider}>
                    Add Slider
                </button>
            </div>
            <div class="mt-8 h-full overflow-x-scroll">
                <table {...getTableProps()} className="w-full">
                    <thead>
                        {headerGroups.map((headerGroup, index) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                {headerGroup.headers.map((column, index) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        key={index}
                                        className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                                    >
                                        <p className="text-xs tracking-wide text-gray-600">
                                            {column.render("Header")}
                                        </p>
                                    </th>
                                ))}
                                <th className="border-b border-gray-300 uppercase pb-[10px] text-start dark:!border-navy-700 "><div className="text-xs font-bold tracking-wide text-gray-600">
                                    Actions
                                </div></th>
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, index) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} key={index}>
                                    {row.cells.map((cell, index) => {
                                        let data = "";
                                        if (cell.column.Header === "IMAGE") {
                                            data = (
                                               <img src={cell.value} alt="" className=" w-28 h-28 object-contain" crossOrigin="anonymous" />
                                            );
                                        } else if (cell.column.Header === "HEADING") {
                                            data = (
                                              
                                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                    {cell.value}
                                                </p>
                                            );
                                        } else if (cell.column.Header === "SUBHEADING") {
                                            data = (
                                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                    {cell.value}
                                                </p>
                                            );
                                        }
                                       
                                        
                                        return (
                                            <>
                                                <td
                                                    className="pt-[14px] pb-[18px] sm:text-[14px]"
                                                    {...cell.getCellProps()}
                                                    key={index}
                                                >
                                                    {data}
                                                </td>
                                                {
                                                    row.cells.length - 1 === index ?
                                                    <td>
                                                        <div className="w-full flex items-center h-full">
                                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-medium py-1 px-2 text-sm rounded-md mr-2 mt-2" onClick={() => editProduct(cell.row.original._id)}>
                                                            <BiEdit />
                                                        </button>
                                                        <button className="bg-red-500 hover:bg-red-700 text-white font-medium py-1 px-2 text-sm rounded-md mt-2" onClick={() => deleteProduct(cell.row.original._id)}>
                                                            <MdDelete />
                                                        </button>
                                                        </div>
                                                       
                                                    </td> : null

                                                }

                                            </>
                                        );
                                    })}

                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </Card>
    );
};

export default SliderTable;
