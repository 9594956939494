import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import Products from './pages/product/product'
import CreateProducts from './pages/product/createProduct'
import EditProducts from './pages/product/editProduct'
import Orders from './pages/orders/order'
import OrderDetail from './pages/orders/orderDetail'
import Profile from "views/admin/profile";
import Reviews from "./pages/Reviews/Reviews";
import Coupon from "./pages/Coupon/Coupon";
import Categories from "./pages/Categories/Categories";
import CreateCoupon from "pages/Coupon/CreateCoupon";
import EditCoupon from "pages/Coupon/EditCoupon";
import Cms from "pages/CMS/Cms";
import GeneralSetting from "pages/GeneralSetting/GeneralSetting"
import EditSocialMedia from "pages/GeneralSetting/EditSocialMedia"
import CreateSocialMedia from "pages/GeneralSetting/CreateSocialMedia"
import CreateSlider from "pages/GeneralSetting/CreateSlider"
import EditSlider from "pages/GeneralSetting/EditSlider"
// Auth Imports

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
} from "react-icons/md";

const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Products",
    layout: "/admin",
    path: "products",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <Products />,
  },
  {
    name: "Create Products",
    layout: "/admin",
    path: "create-product",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <CreateProducts />,
  },
  {
    name: "Edit Products",
    layout: "/admin",
    path: "edit-product/:id",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <EditProducts />,
  },
  {
    name: "Orders",
    layout: "/admin",
    path: "orders",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <Orders />,
    
  },
  {
    name: "Order Detail",
    layout: "/admin",
    path: `order-detail/:id`,
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <OrderDetail />,
    
  },
  {
    name: "Categories",
    layout: "/admin",
    path: "categories",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <Categories />,
    
  },
  {
    name: "Reviews",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "reviews",
    component: <Reviews />,
  },
  {
    name: "Coupon",
    layout: "/admin",
    path: "coupon",
    icon: <MdHome className="h-6 w-6" />,
    component: <Coupon />,
  },
  {
    name: "Create Coupon",
    layout: "/admin",
    path: "create-coupon",
    icon: <MdHome className="h-6 w-6" />,
    component: <CreateCoupon />,
  },
  {
    name: "Edit Coupon",
    layout: "/admin",
    path: `edit-coupon/:id`,
    icon: <MdHome className="h-6 w-6" />,
    component: <EditCoupon />,
  },
  {
    name: "CMS Pages",
    layout: "/admin",
    path: "cms-pages",
    icon: <MdHome className="h-6 w-6" />,
    component: <Cms />,
  },
  {
    name: "Setting",
    layout: "/admin",
    path: "setting",
    icon: <MdHome className="h-6 w-6" />,
    component: <GeneralSetting />,
  },
  {
    name: "Edit Social Media",
    layout: "/admin",
    path: "edit-social-media/:id",
    icon: <MdHome className="h-6 w-6" />,
    component: <EditSocialMedia />,
  },
  {
    name: "Create Social Media",
    layout: "/admin",
    path: "create-social-media",
    icon: <MdHome className="h-6 w-6" />,
    component: <CreateSocialMedia />,
  },
  {
    name: "Create Slider",
    layout: "/admin",
    path: "create-slider",
    icon: <MdHome className="h-6 w-6" />,
    component: <CreateSlider />,
  },
  {
    name: "Edit SLider",
    layout: "/admin",
    path: "edit-slider/:id",
    icon: <MdHome className="h-6 w-6" />,
    component: <EditSlider />,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  }

];
export default routes;
