import axios from "axios";
import { StorageItem, getItem } from "./local-storage.utils";
import { setHeaders } from "./api_service";

export const JWTInterceptor = () => {
  axios.interceptors.request.use((config) => {
    if(config.data instanceof FormData) {
      config.headers = {
        'LOCALE': 'en',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data'
      } 
    }
    else {
      config.headers = setHeaders();
    }
    const token = getItem(StorageItem.JwtToken);
    if(token){
      const newHeaders = {...config?.headers, 'x-access-token': token}
      config.headers = newHeaders;
      return config
    }
    return config
  });
}