import React, { useEffect, useState } from 'react'
import InputField from 'components/fields/InputField'
import { createSocialMediaImg } from 'store/globalState';
import { useDispatch, useSelector } from 'react-redux';
import { postSocialMedia } from 'store/globalState';
import { useNavigate } from 'react-router-dom';
import { fetchSocialMedia } from 'store/globalState';

function CreateSocialMedia() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const createSocImg = useSelector((state) => state.global_state.createSocImg);
    const [createSocialMedia, setCreateSocialMedia] = useState({
        name: "",
        url: "",
        image: {
         url: ""
    }
    });
    const [main_image , setMainImage] = useState({url: ''});
    useEffect(() => {
        setMainImage(createSocialMedia?.image?.url);
        setCreateSocialMedia(createSocialMedia);
    }, [createSocialMedia])
    useEffect(() => {
        if(createSocImg) {
            setMainImage({url: createSocImg})
            setCreateSocialMedia(Object.assign(createSocialMedia,  {image: {url : createSocImg}} ))
        }
    }, [createSocialMedia, createSocImg])
    const addSocialMediaImg = (event) => {
        const file = event?.target?.files[0];
        if(file) {        
            const formData = new FormData();
            formData.append('image', file);
            dispatch(createSocialMediaImg(formData))
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if(createSocialMedia)
        {
           await dispatch(postSocialMedia(createSocialMedia))
            await Navigate()
        }
    }

    const handleChange = (event) => {
        setCreateSocialMedia(
            {
                ...createSocialMedia,
                [event.target.name] : event.target.value
            }
        )
    }
    const Navigate = async () => {
        navigate('/admin/setting')
    }
    return (
        <div className='w-full h-full'>
            <form onSubmit={handleSubmit}>
            <div class="w-full mt-3">
              <h4  className={"text-sm text-navy-700 dark:text-white ml-1.5 font-medium text-center w-full mb-3"}>Logo*</h4>
              <div onChange={addSocialMediaImg} class="flex items-center justify-center w-full mt-3">
                        <label for="dropzone-filesssss" class="flex flex-col items-center justify-center w-full max-w-[300px] mx-auto h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                            <div class="flex flex-col items-center justify-center w-full h-full">
                                {
                                    main_image?.url ? 
                                    <img className='w-40 object-contain h-full' crossOrigin='anonymous' src={main_image?.url} alt='img'/>
                                    :
                                    <div>
                                        <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400 text-center"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                                        <p class="text-xs text-gray-500 dark:text-gray-400 text-center">PNG or JPG</p>
                                    </div>
                                }
                            </div>
                            <input id="dropzone-filesssss" type="file" class="hidden" accept="image/png, image/jpeg"/>
                        </label>
                    </div>
            </div>
            <div className='grid grid-cols-2 gap-4 w-full mt-5'>
                <InputField
                    variant="auth"
                    extra="mb-3 w-full pr-2"
                    label="Name*"
                    placeholder="Enter Name .."
                    id="name"
                    type="text"
                    name="name"
                    value={createSocialMedia?.name || ""}
                    onChange={handleChange}
                />
                <InputField
                    variant="auth"
                    extra="mb-3 w-full pr-2"
                    label="URL*"
                    placeholder="Enter URL.."
                    id="url"
                    type="text"
                    name="url"
                    value={createSocialMedia?.url || ""}
                    onChange={handleChange}
                />
             
            </div>
            <div className="flex justify-end mt-7">
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type='submit' >
                    Create
                </button>
            </div>
            </form>
        </div>
    )
}

export default CreateSocialMedia





