import InputField from "components/fields/InputField";
// import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postSignIn } from "store/globalState";
import { useNavigate } from "react-router-dom";

export default function SignIn() {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(
    {
      email_address: '',
      password: ''
    }
  )

  const handleSubmit = async (event) => 
  {
      event.preventDefault();
      try {
        await dispatch(postSignIn(formData));

        navigate("/admin/default")
      } catch (error) {
        console.error("Sign-in failed", error);
      }
  }

  const handleChange = (event) => {
    // const { name, value } = event.target;
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
    // switch (name) {
    //   case 'email_address':
    //     setValidationErrors((prevErrors) => ({
    //       ...prevErrors,
    //       email_address: validateEmail(value),
    //     }));
    //     break;
    //   case 'password':
    //     setValidationErrors((prevErrors) => ({
    //       ...prevErrors,
    //       password: validatePassword(value),
    //     }));
    //     break;
    //   default:
    //     break;
    // }
    // const validateEmail = (email) => {
    //   if (!/\S+@\S+\.\S+/.test(email)) {
    //     return 'Please enter a valid email address';
    //   }
    //   return '';
    // };

    // const validatePassword = (password) => {
    //   const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s]).{8,}$/;
    //   if (password.length < 8) {
    //     return 'Password must be at least 8 characters long';
    //   }
    //   if (!passwordRegex.test(password)) {
    //     return 'Password must contain at least one uppercase letter, one digit, and one special character';
    //   }
    //   return '';
    // };
  };
  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>
        {/* Email */}
        <form onSubmit={handleSubmit}>
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="mail@simmmple.com"
          id="email"
          type="text"
          value={formData.email_address}
          name='email_address'
          onChange = {handleChange}
        />

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 8 characters"
          id="password"
          type="password"
          value={formData.password}
          name='password'
          onChange={handleChange}
         
        />
        {/* Checkbox */}
        <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div>
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
          >
            Forgot Password?
          </a>
        </div>
        <button type='submit' className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Sign In
        </button>
        </form>
      </div>
    </div>
  );
}
