
import Card from "components/card";
import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";
import { useMemo , useState} from "react";
import { useDispatch } from "react-redux";
import { deleteReview } from "store/globalState";
import { FiSearch } from "react-icons/fi";
import { fetchSearchReview } from "store/globalState";
import { MdDelete } from "react-icons/md";
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";


const ReviewsTable = (props) => {
    const dispatch = useDispatch();
    const { columnsData, tableData } = props;
    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData || [], [tableData]);
    const [deleteProdID, setDeleteProdID] = useState();
    const [delRevID, setDelRevID] = useState();
    const tableInstance = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        initialState,
    } = tableInstance;
    initialState.pageSize = 5;

    const deleteProduct = (prodId, reviewId) => {
        const data = {
            productId: prodId,
            reviewId: reviewId
        }
        console.log("data", data)
        // dispatch(deleteReview(data))
    }

    const debounce = (func, timeout = 500) => {
        let timer;
        return (...args) => {
          clearTimeout(timer);
          timer = setTimeout(() => func.apply(this, args), timeout)
        }
      }
      const handleKeyDown = (event) => {
        const value = event.target.value;
        dispatch(fetchSearchReview(value))
      }
      const OverlayOne = () => (
        <ModalOverlay
          bg='blackAlpha.300'
          backdropFilter='blur(10px) hue-rotate(90deg)'
        />
      )
      const { isOpen, onOpen, onClose } = useDisclosure()
      const [overlay, setOverlay] = useState(<OverlayOne />)
    return (
        <>
        <Card extra={"w-full h-full p-4 sm:overflow-x-auto"}>
            <div class="relative flex items-center justify-between">
                <div className="flex h-11 items-center rounded-full  bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
                    <p className="pl-3 pr-2 text-xl">
                        <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                    </p>
                    <input
                        type="text"
                        placeholder="Search By Title..."
                        class="block h-12 w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                        onKeyDown={debounce(handleKeyDown)}
                    />
                </div>
              
            </div>
            <div class="mt-8 h-full overflow-x-scroll">
                <table {...getTableProps()} className="w-full">
                    <thead>
                        {headerGroups.map((headerGroup, index) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                {headerGroup.headers.map((column, index) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        key={index}
                                        className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                                    >
                                        <p className="text-xs tracking-wide text-gray-600">
                                            {column.render("Header")}
                                        </p>
                                    </th>
                                ))}
                                <th className="border-b border-gray-300 uppercase pb-[10px] text-start dark:!border-navy-700 "><div className="text-xs font-bold tracking-wide text-gray-600">
                                    Actions
                                </div></th>
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, index) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} key={index}>
                                    {row.cells.map((cell, index) => {
                                        let data = "";
                                        if (cell.column.Header === "IMAGE") {
                                            data = (
                                                <img src={cell.value} alt="" className=" w-28 h-28 object-contain" />
                                            );
                                        } else if (cell.column.Header === "TITLE") {
                                            data = (

                                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                    {cell.value}
                                                </p>
                                            );
                                        } else if (cell.column.Header === "CUSTOMER") {
                                            data = (
                                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                    {cell.value}
                                                </p>
                                            );
                                        }
                                        else if (cell.column.Header === "RATING") {
                                            data = (

                                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                    {cell.value}
                                                </p>
                                            );
                                        }
                                        else if (cell.column.Header === "DATE") {
                                            data = (
                                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                    {cell.value}
                                                </p>
                                            );
                                        }

                                        return (
                                            <>
                                                <td
                                                    className="pt-[14px] pb-[18px] sm:text-[14px]"
                                                    {...cell.getCellProps()}
                                                    key={index}
                                                >
                                                    {data}
                                                </td>
                                                {
                                                    row.cells.length - 1 === index ?
                                                        <td>
                                                            <button className="bg-red-500 hover:bg-red-700 text-white font-medium py-1 px-4 text-sm rounded-md mt-2" onClick={() => {
                                                                  setOverlay(<OverlayOne />)
                                                                  onOpen()
                                                                  setDeleteProdID(cell.row.original.productId)
                                                                  setDelRevID(cell.row.original.reviewId)}}><MdDelete /></button>
                                                        </td> : null

                                                }

                                            </>
                                        );
                                    })}

                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </Card>
          <Modal isCentered isOpen={isOpen} onClose={onClose}>
          {overlay}
          <ModalContent>
            <ModalHeader>Delete a Product</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>Are you sure you want to delete this prdouct</Text>
            </ModalBody>
            <ModalFooter>
              <Button className="mx-3" onClick={() => deleteProduct(deleteProdID,delRevID)} >Yes</Button>
              <Button onClick={onClose}>No</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        </>
    );
};

export default ReviewsTable;
