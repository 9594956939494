import React, { useEffect } from 'react'
import CouponTable from 'views/admin/tables/components/CouponTable'
import { CouponColData } from 'views/admin/tables/variables/columnsData'
import { useDispatch, useSelector } from 'react-redux';
import { fetchCoupon } from 'store/globalState';
import Paginate from 'components/Pagination/Paginate';

function Coupon() {
  const dispatch = useDispatch();
  const coupon = useSelector((state) => state.global_state.coupon?.coupons)
  useEffect(() => {
    dispatch(fetchCoupon())
  },[dispatch])
  const [currentPage, setCurrentPage] = React.useState(1);
  const recordsPerPage = 5; // Set the number of records per page
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const currentData = coupon?.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage);
  return (
    <div className='mt-[2.25rem] h-full w-full'>
        <CouponTable
            columnsData = {CouponColData}
            tableData={currentData}
        />
         <Paginate totalRecords={coupon?.length} recordsPerPage={recordsPerPage} onPageChange={handlePageChange} />
    </div>
  )
}

export default Coupon