import InputField from 'components/fields/InputField'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { postCoupon } from 'store/globalState';

function CreateCoupon() {
    const dispatch = useDispatch();
    const[formData , setFormData] = useState({
        name : "",
        code : "",
        discount : 0,
        expirationTime : ""
    })
    const handleChange = (event) => {
     setFormData(
        {
            ...formData,
        [event.target.name]: event.target.value
    }
     )
    }
    const handleSubmit = (event) => 
    {
        event.preventDefault();
        dispatch(postCoupon(formData))
    }
    return (
        <div className="mt-[2.25rem] h-full w-full">
            <form action="" onSubmit={handleSubmit}>
                <div className='grid grid-cols-2 gap-4 w-full'>
                    <InputField
                        variant="auth"
                        extra="mb-3 w-full pr-2"
                        label="Name*"
                        placeholder="Enter Name.."
                        id="name"
                        type="text"
                        name="name"
                        onChange={handleChange}

                    />
                    <InputField
                        variant="auth"
                        extra="mb-3 w-full pr-2"
                        label="Code*"
                        placeholder="Enter Code.."
                        id="code"
                        type="text"
                        name="code"
                        onChange={handleChange}

                    />
                </div>
                <div className='grid grid-cols-2 gap-4 w-full'>
                    <InputField
                        variant="auth"
                        extra="mb-3 w-full pr-2"
                        label="Discount*"
                        placeholder="Enter Discount.."
                        id="discount"
                        type="text"
                        name="discount"
                        onChange={handleChange}

                    />
                    <InputField
                        variant="auth"
                        extra="mb-3 w-full pr-2"
                        label="Expiration Time*"
                        placeholder="Enter Expiration Time.."
                        id="expirationTime"
                        type="datetime-local"
                        name="expirationTime"
                        onChange={handleChange}
                    />
                </div>
                <div class="grid grid-cols-1 mt-5 mx-3">
                    <div class="col-start-12 col-span-2">
                        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type='submit'>
                            Create
                        </button>
                    </div>
                </div>
            </form>


        </div>

    )
}

export default CreateCoupon