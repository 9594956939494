import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSlider } from 'store/globalState';
import SliderTable from 'views/admin/tables/components/SliderTable';
import { SliderColData } from 'views/admin/tables/variables/columnsData';


function Slider() {
    const dispatch = useDispatch();
    const slider = useSelector((state) => state.global_state.slider?.sliders)
    useEffect(() => {
        dispatch(fetchSlider());
    },[])
    return(
        <div className='mt-[2.25rem] h-full w-full'>
        <SliderTable
            columnsData = {SliderColData}
            tableData={slider}
        />
         
    </div>
        
    )
}

export default Slider