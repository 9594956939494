import { StorageItem } from "Api_Handling/local-storage.utils";
import { getSessionItem } from "Api_Handling/local-storage.utils";
import { getItem } from "Api_Handling/local-storage.utils";
import InputField from "components/fields/InputField";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userDobaraSetKr } from "store/globalState";


const ProfileOverview = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.global_state.signIn?.user);

    const [formData, setFormData] = useState(user || "");
    useEffect(() => {
        if (user) {
          setFormData(user)
        //   dispatch(fetchCountry());
        }
        else {
          let userNaya = {
            message: "login successfully",
            token: getItem(StorageItem.JwtToken) || getSessionItem(StorageItem.JwtToken),
            user: getItem(StorageItem.LoggedInUser) || getSessionItem(StorageItem.LoggedInUser)
          }
          dispatch(userDobaraSetKr(userNaya));
          setFormData(userNaya)
        }
      }, [dispatch, user]);

      const handleChange = (event) => {
        setFormData({
          ...formData,
          [event.target.name]: event.target.value
        });
      };
  return (
    <div className="flex w-full flex-col gap-5">
      <div className="w-ful mt-3 h-fit">
        <div className='grid grid-cols-2 gap-4 w-full'>
                <InputField
                    variant="auth"
                    extra="mb-3 w-full pr-2"
                    label="First Name*"
                    placeholder="Enter First Name.."
                    id="first_name"
                    type="text"
                    name = "first_name"
                    value={formData?.user?.first_name || ""}
                    onChange={handleChange}
                />
               <InputField
                    variant="auth"
                    extra="mb-3 w-full pr-2"
                    label="Last Name*"
                    placeholder="Enter Last Name.."
                    id="last_name"
                    type="text"
                    name = "last_name"
                    value={formData?.user?.last_name || ""}
                    onChange={handleChange}
                />
            </div>
            <div className='grid grid-cols-2 gap-4 w-full'>
                <InputField
                    variant="auth"
                    extra="mb-3 w-full pr-2"
                    label="Company Name*"
                    placeholder="Enter Company Name.."
                    id="company_name"
                    type="text"
                    name = "company_name"
                    value={formData?.user?.company_name || ""}
                    onChange={handleChange}
                />
               <InputField
                    variant="auth"
                    extra="mb-3 w-full pr-2"
                    label="Country*"
                    placeholder="Enter Country.."
                    id="country"
                    type="text"
                    name = "country"
                    value={formData?.user?.country || ""}
                    onChange={handleChange}
                />
            </div>
            <div className='grid grid-cols-2 gap-4 w-full'>
                <InputField
                    variant="auth"
                    extra="mb-3 w-full pr-2"
                    label="City*"
                    placeholder="Enter City.."
                    id="city"
                    type="text"
                    name = "city"
                    value={formData?.user?.city || ""}
                    onChange={handleChange}
                />
               <InputField
                    variant="auth"
                    extra="mb-3 w-full pr-2"
                    label="Street Address*"
                    placeholder="Enter Street Address.."
                    id="street_address"
                    type="text"
                    name = "street_address"
                    value={formData?.user?.street_address || ""}
                    onChange={handleChange}
                />
            </div>
            <div className='grid grid-cols-2 gap-4 w-full'>
                <InputField
                    variant="auth"
                    extra="mb-3 w-full pr-2"
                    label="Postal Code*"
                    placeholder="Enter Postal Code.."
                    id="postal_code"
                    type="text"
                    name = "postal_code"
                    value={formData?.user?.postal_code || ""}
                    onChange={handleChange}
                />
               <InputField
                    variant="auth"
                    extra="mb-3 w-full pr-2"
                    label="Email Address*"
                    placeholder="Enter Email Address.."
                    id="email_address"
                    type="text"
                    name = "email_address"
                    value={formData?.user?.email_address || ""}
                    onChange={handleChange}
                />
            </div>
            <div className='grid grid-cols-2 gap-4 w-full'>
                <InputField
                    variant="auth"
                    extra="mb-3 w-full pr-2"
                    label="Phone Number*"
                    placeholder="Enter Phone Number.."
                    id="phone_number"
                    type="text"
                    name = "phone_number"
                    value={formData?.user?.phone_number || ""}
                    onChange={handleChange}
                />
            </div>
        </div>
        <div className="flex justify-end mt-7">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Update
            </button>
        </div>
    </div>
  );
};

export default ProfileOverview;
