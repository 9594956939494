import axios from "axios";

export const ServerErrorInterceptor = () => {

    axios.interceptors.response.use((response) => {
       return response
    },
    (error) => 
    {
        if([401,403].includes(error.response.status))
        {
            console.log("API Error")
        }
    }
    )
}