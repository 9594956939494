import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "Api_Handling/api_service";
import { postMedia } from "Api_Handling/api_service";
import { del } from "Api_Handling/api_service";
import { patch } from "Api_Handling/api_service";
import { put } from "Api_Handling/api_service";
import { get } from "Api_Handling/api_service";
import { getItem } from "Api_Handling/local-storage.utils";
import { removeItem } from "Api_Handling/local-storage.utils";
import { getSessionItem } from "Api_Handling/local-storage.utils";
import { setSessionItem } from "Api_Handling/local-storage.utils";
import { StorageItem } from "Api_Handling/local-storage.utils";
import { setItem } from "Api_Handling/local-storage.utils";


const initialState =
{
  productEdit: {},
  productList: [],
  productCreate: {},
  orderList: [],
  signIn: {},
  login: false,
  orderDetail: {},
  categories: [],
  mainImageUrl: null,
  settingImg: null,
  paymentImg: null,
  socialMediaImg: null,
  uploadSliderImg: null,
  createSocImg: null,
  createSlImg: null,
  productImages: [],
  updatedProduct: [],
  delProd: {},
  reviews: {},
  coupon: {},
  editCoupon : {},
  totalEarning: {},
  pendingOrders: {},
  averageDailySales: {},
  allOrders: {},
  aboutUs:{},
  contactUs:{},
  moneyBackGurante:{},
  safeShopping: {},
  returnPolicy: {},
  generalSetting: {},
  appStore: {},
  socialMedia: {},
  editSocialMedia: {},
  slider: {},
  editSlider: {},
  topCountries: {},
  totalRevenue: {},
  dailyTraffic: {},
  isLoading: false,
  updateAlert: false,
  createAlert: false,
  deleteAlert: false
}



export const fetchProductEdit = createAsyncThunk('fetchProductEdit' ,
async (id) => {
        return await get(`product/${id}`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const fetchProductList = createAsyncThunk('fetchProductList' ,
async () => {
        return await get('product').then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const fetchOrderList = createAsyncThunk('fetchOrderList',
    async (params) => {
        return await get('order/all-orders', params).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const postSignIn = createAsyncThunk('postSignIn' , 
async (data) => 
{
    return await post('user/login',data).then(res => {
        if(!res.hasErrors())
        {
            return res.data
        }
        else{
            throw res.errors[0].err?.text
        }
    })
}
)

export const fetchOrderDetail = createAsyncThunk('fetchOrderDetail',
async(id) =>
{
    return await get(`order/${id}`).then(res => {
        if(!res.hasErrors()) {
            return res.data
        }
        else {
            throw res.errors[0].err?.text
        }
    })
}
)

export const fetchCategories = createAsyncThunk('fetchCategories',
async() =>
{
    return await get('product/categories').then(res => {
        if(!res.hasErrors()) {
            return res.data
        }
        else {
            throw res.errors[0].err?.text
        }
    })
}
)

export const uploadNewImage = createAsyncThunk('uploadNewImage',
    async(file) =>
    {
        return await postMedia('upload/file', file).then(res => {
            if(!res.hasErrors()) {
                return res.data?.image
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
)

export const uploadSettingImg = createAsyncThunk('uploadSettingImg',
    async(file) =>
    {
        return await postMedia('upload/file', file).then(res => {
            if(!res.hasErrors()) {
                return res.data?.image
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
)

export const uploadPaymentImg = createAsyncThunk('uploadPaymentImg',
    async(file) =>
    {
        return await postMedia('upload/file', file).then(res => {
            if(!res.hasErrors()) {
                return res.data?.image
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
)

export const uploadSocialMedia = createAsyncThunk('uploadSocialMedia',
    async(file) =>
    {
        return await postMedia('upload/file', file).then(res => {
            if(!res.hasErrors()) {
                return res.data?.image
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
)
export const createSocialMediaImg = createAsyncThunk('createSocialMediaImg',
    async(file) =>
    {
        return await postMedia('upload/file', file).then(res => {
            if(!res.hasErrors()) {
                return res.data?.image
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
)

export const createSliderImg = createAsyncThunk('createSliderImg',
    async(file) =>
    {
        return await postMedia('upload/file', file).then(res => {
            if(!res.hasErrors()) {
                return res.data?.image
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
)

export const uploadSlider = createAsyncThunk('uploadSlider',
    async(file) =>
    {
        return await postMedia('upload/file', file).then(res => {
            if(!res.hasErrors()) {
                return res.data?.image
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
)

export const uploadMultipleImages = createAsyncThunk('uploadMultipleImages',
    async(files) =>
    {
        return await postMedia('upload/files', files).then(res => {
            if(!res.hasErrors()) {
                const result = res.data?.images?.map(val => val)
                return result
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
)

export const createProduct = createAsyncThunk('createProduct' , 
async (data) => 
{
    return await post('product',data).then(res => {
        if(!res.hasErrors())
        {
            return res.data
        }
        else{
            throw res.errors[0].err?.text
        }
    })
}
)

export const UpdateProduct = createAsyncThunk('UpdateProduct' , 
async ({data, id}) => 
{
    return await put(`product/${id}` , data ).then(res => {
        if(!res.hasErrors())
        {
            return res.data
        }
        else{
            throw res.errors[0].err?.text
        }
    })
}
)
export const bulkUpload = createAsyncThunk('bulkUpload' , 
async (file) => 
{
    return await postMedia("product/upload" , file ).then(res => {
        if(!res.hasErrors())
        {
            return res.data
        }
        else{
            throw res.errors[0].err?.text
        }
    })
}
)
export const deleteProd = createAsyncThunk('deleteProd' , 
async (id) => 
{
    return await del(`product/${id}`).then(res => {
        if(!res.hasErrors())
        {
            return res.data
        }
        else{
            throw res.errors[0].err?.text
        }
    })
}
)

export const fetchProdReviews = createAsyncThunk('fetchProdReviews',
async() =>
{
    return await get("review").then(res => {
        if(!res.hasErrors()) {
            return res.data
        }
        else {
            throw res.errors[0].err?.text
        }
    })
}
)

export const deleteReview = createAsyncThunk('deleteReview' , 
async (data) => 
{
    return await del("review",data).then(res => {
        if(!res.hasErrors())
        {
            return res.data
        }
        else{
            throw res.errors[0].err?.text
        }
    })
}
)

export const fetchCoupon = createAsyncThunk('fetchCoupon',
async() =>
{
    return await get("coupon").then(res => {
        if(!res.hasErrors()) {
            return res.data
        }
        else {
            throw res.errors[0].err?.text
        }
    })
}
)

export const postCoupon = createAsyncThunk('postCoupon' , 
async (data) => 
{
    return await post('coupon',data).then(res => {
        if(!res.hasErrors())
        {
            return res.data
        }
        else{
            throw res.errors[0].err?.text
        }
    })
}
)

export const fetchEditCoupon = createAsyncThunk('fetchEditCoupon',
async(id) =>
{
    return await get(`coupon/${id}`).then(res => {
        if(!res.hasErrors()) {
            return res.data
        }
        else {
            throw res.errors[0].err?.text
        }
    })
}
)

export const updateCoupon = createAsyncThunk('updateCoupon' , 
async (data) => 
{
    return await put(`coupon/${data.id}` , data.formData ).then(res => {
        if(!res.hasErrors())
        {
            return res.data
        }
        else{
            throw res.errors[0].err?.text
        }
    })
}
)
export const deleteCoupon = createAsyncThunk('deleteCoupon' , 
async (id) => 
{
    return await del(`coupon/${id}`).then(res => {
        if(!res.hasErrors())
        {
            return res.data
        }
        else{
            throw res.errors[0].err?.text
        }
    })
}
)
export const fetchTotalEarning = createAsyncThunk('fetchTotalEarning' ,
async (data) => {
        return await get('order/total-orders', DataView).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);
export const fetchPendingOrders = createAsyncThunk('fetchPendingOrders' ,
async () => {
        return await get('order/pending-order').then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);
export const fetchAllOrders = createAsyncThunk('fetchAllOrders' ,
async () => {
        return await get('order/allorders').then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);
export const fetchAvgDailySales = createAsyncThunk('fetchAvgDailySales' ,
async () => {
        return await get('order/average-daily-sale').then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);
export const fetchAboutUs = createAsyncThunk('fetchAboutUs' ,
async () => {
        return await get('about-us').then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const updateAboutUs = createAsyncThunk('updateAboutUs' ,
async (data) => {
        return await patch('about-us', data).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const fetchContactUs = createAsyncThunk('fetchContactUs' ,
async () => {
        return await get('contact-us').then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const updateContactUs = createAsyncThunk('updateContactUs' ,
async (data) => {
        return await patch('contact-us', data).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const fetchMoneyBackGurante = createAsyncThunk('fetchMoneyBackGurante' ,
async () => {
        return await get('moneyback-guarantee').then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const updateMoneyBackGurante = createAsyncThunk('updateMoneyBackGurante' ,
async (data) => {
        return await patch('moneyback-guarantee', data).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const fetchSafeShopping = createAsyncThunk('fetchSafeShopping' ,
async () => {
        return await get('safe-shopping').then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const updateSafeShopping = createAsyncThunk('updateSafeShopping' ,
async (data) => {
        return await patch('safe-shopping',data).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const fetchReturnPolicy = createAsyncThunk('fetchReturnPolicy' ,
async () => {
        return await get('return-policy').then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const updateReturnPolicy = createAsyncThunk('updateReturnPolicy' ,
async (data) => {
        return await patch('return-policy', data).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const fetchGeneralSetting = createAsyncThunk('fetchGeneralSetting' ,
async () => {
        return await get('general-setting').then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const UpdateGeneralSetting = createAsyncThunk('UpdateGeneralSetting' ,
async (data) => {
        return await patch('general-setting', data).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const fetchAppStore = createAsyncThunk('fetchAppStore' ,
async () => {
        return await get('appstore').then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);


export const UpdateAppStore = createAsyncThunk('UpdateAppStore' ,
async (data) => {
        return await patch('appstore', data).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const fetchSocialMedia = createAsyncThunk('fetchSocialMedia' ,
async () => {
        return await get('social-media').then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const editSocialMedia = createAsyncThunk('editSocialMedia' ,
async (id) => {
        return await get(`social-media/${id}`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);


export const updateSocialMedia = createAsyncThunk('updateSocialMedia' ,
async (data) => {
        return await patch(`social-media`, data).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const fetchSlider = createAsyncThunk('fetchSlider' ,
async () => {
        return await get('slider').then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const editSlider = createAsyncThunk('editSlider' ,
async (id) => {
        return await get(`slider/${id}`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const fetchTopCountries = createAsyncThunk('fetchTopCountries' ,
async () => {
        return await get('order/top-3').then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const fetchTotalRevenue = createAsyncThunk('fetchTotalRevenue' ,
async (data) => {
        return await post('order/revenue', data).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const fetchDailyTraffic = createAsyncThunk('fetchDailyTraffic' ,
async () => {
        return await get('view/weekly-views').then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const updateSlider = createAsyncThunk('updateSlider' ,
async (data) => {
        return await patch(`slider`, data).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const fetchSearchProducts = createAsyncThunk('fetchSearchProducts',
    async (text) => {
        if(text.length >= 1)
        {
            return await get(`product/admin/search?text=${text}`).then(res => {
                if(!res.hasErrors()) {
                    return res.data
                }
                else {
                    throw res.errors[0].err?.text
                }
            })
        }

        else{
            return await get('product').then(res => {
                if(!res.hasErrors()) {
                    return res.data
                }
                else {
                    throw res.errors[0].err?.text
                }
            })
        }
       

    }
);

export const updateMainCate = createAsyncThunk('updateMainCate' ,
async (data) => {
        return await put(`category`, data).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const updateSubCate = createAsyncThunk('updateSubCate' ,
async (data) => {
        return await put(`product/sub-category`, data).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);
export const fetchSearchCoupon = createAsyncThunk('fetchSearchCoupon',
    async (text) => {
        if(text.length >= 1)
        {
            return await get(`coupon/search?code=${text}`).then(res => {
                if(!res.hasErrors()) {
                    return res.data
                }
                else {
                    throw res.errors[0].err?.text
                }
            })
        }

        else{
            return await get('coupon').then(res => {
                if(!res.hasErrors()) {
                    return res.data
                }
                else {
                    throw res.errors[0].err?.text
                }
            })
        }
       

    }
);

export const fetchSearchReview = createAsyncThunk('fetchSearchReview',
    async (text) => {
        if(text.length >= 1)
        {
            return await get(`review/search?name=${text}`).then(res => {
                if(!res.hasErrors()) {
                    return res.data
                }
                else {
                    throw res.errors[0].err?.text
                }
            })
        }

        else{
            return await get('review').then(res => {
                if(!res.hasErrors()) {
                    return res.data
                }
                else {
                    throw res.errors[0].err?.text
                }
            })
        }
       

    }
);

export const postSocialMedia = createAsyncThunk('postSocialMedia' , 
async (data) => 
{
    return await post('social-media',data).then(res => {
        if(!res.hasErrors())
        {
            return res.data
        }
        else{
            throw res.errors[0].err?.text
        }
    })
}
)

export const deleteSocialMedia = createAsyncThunk('deleteSocialMedia' , 
async (id) => 
{
    return await del(`social-media/${id}`).then(res => {
        if(!res.hasErrors())
        {
            return res.data
        }
        else{
            throw res.errors[0].err?.text
        }
    })
}
)

export const postSlider = createAsyncThunk('postSlider' , 
async (data) => 
{
    return await postMedia('slider',data).then(res => {
        if(!res.hasErrors())
        {
            return res.data
        }
        else{
            throw res.errors[0].err?.text
        }
    })
}
)

export const postMainCategory = createAsyncThunk('postMainCategory' , 
async (data) => 
{
    return await post('category',data).then(res => {
        if(!res.hasErrors())
        {
            return res.data
        }
        else{
            throw res.errors[0].err?.text
        }
    })
}
)

const globalState = createSlice({
    name: 'global_state',
    initialState,
    reducers:
    {
        hideUpdateAlert: (state, action) =>
        {
            state.updateAlert = false;
        },
        hideCreateAlert: (state,action) => {
            state.createAlert = false;
        },
        hideDeleteAlert: (state, action) => {
            state.deleteAlert = false;
        },
        
        userDobaraSetKr: (state, action) => {
            state.signIn = action?.payload;
        },
        logout: (state, action) => 
        {
            removeItem(StorageItem.JwtToken)
            removeItem(StorageItem.LoggedInUser)
            state.login = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProductEdit.fulfilled, (state, action) => {
            state.productEdit = action.payload;
            state.isLoading = false;
        })
        builder.addCase(fetchProductEdit.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchProductList.fulfilled, (state, action) => {
            state.productList = action.payload;
            state.isLoading = false;
        })
        builder.addCase(fetchProductList.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchOrderList.fulfilled, (state, action) => {
            state.orderList = action.payload;
            state.isLoading = false;
        })
        builder.addCase(fetchOrderList.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(postSignIn.fulfilled, (state, action) => {
            state.signIn = action.payload;
            state.login = true;
            if(state.login === true)
            {
                setItem(StorageItem.JwtToken, action?.payload?.token)
                setItem(StorageItem.LoggedInUser, action?.payload)
            }
            else
            {
                setSessionItem(StorageItem.JwtToken, action?.payload?.token)
                setSessionItem(StorageItem.LoggedInUser, action?.payload)
            }
            state.isLoading = false;
        })
        builder.addCase(postSignIn.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchOrderDetail.fulfilled, (state, action) => {
            state.orderDetail = action.payload;
            state.isLoading = false;
        })
        builder.addCase(fetchOrderDetail.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchCategories.fulfilled, (state, action) => {
            state.categories = action.payload;
            state.isLoading = false;
        })
        builder.addCase(fetchCategories.pending, (state, action) => {
            state.isLoading = true;
        })
       
        builder.addCase(uploadNewImage.fulfilled, (state, action) => {
            state.mainImageUrl = action?.payload?.url
        })
        builder.addCase(uploadSettingImg.fulfilled, (state, action) => {
            state.settingImg = action?.payload?.url
        })
        builder.addCase(uploadPaymentImg.fulfilled, (state, action) => {
            state.paymentImg = action?.payload?.url
        })
        builder.addCase(uploadSocialMedia.fulfilled, (state, action) => {
            state.socialMediaImg = action?.payload?.url
        })
        builder.addCase(createSocialMediaImg.fulfilled, (state, action) => {
            state.createSocImg = action?.payload?.url
        })
        builder.addCase(createSliderImg.fulfilled, (state, action) => {
            state.createSlImg = action?.payload?.url
        })
        builder.addCase(uploadSlider.fulfilled, (state, action) => {
            state.uploadSliderImg = action?.payload?.url
        })
      
        builder.addCase(uploadMultipleImages.fulfilled, (state, action) => {
            state.productImages = action.payload
        })

        builder.addCase(createProduct.fulfilled, (state, action) => {
            state.productCreate = action.payload
            state.isLoading = false;
            state.createAlert = true;
        })
        builder.addCase(createProduct.pending, (state, action) => {
            state.isLoading = true;
        })

        builder.addCase(UpdateProduct.fulfilled, (state, action) => {
            state.updatedProduct = action.payload
            state.isLoading = false;
            state.updateAlert = true;
        })
        builder.addCase(UpdateProduct.pending, (state, action) => {
            state.isLoading = true;
        })

        builder.addCase(deleteProd.fulfilled, (state, action) => {
            state.delProd = action.payload
            state.isLoading = false;
            state.deleteAlert = true;
        })
        builder.addCase(deleteProd.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchProdReviews.fulfilled, (state, action) => {
            state.reviews = action.payload
            state.isLoading = false;
        })
        builder.addCase(fetchProdReviews.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(deleteReview.fulfilled, (state, action) => {
            state.deleteAlert = true
        })
        builder.addCase(fetchCoupon.fulfilled, (state, action) => {
            state.coupon = action.payload
            state.isLoading = false;
        })
        builder.addCase(fetchCoupon.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(postCoupon.fulfilled, (state, action) => {
            state.isLoading = false;
        })
        builder.addCase(postCoupon.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchEditCoupon.fulfilled, (state, action) => {
            state.editCoupon =  action.payload
            state.isLoading = false;
        })
        builder.addCase(fetchEditCoupon.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(updateCoupon.fulfilled, (state, action) => {
            state.isLoading = false;
        })
        builder.addCase(updateCoupon.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(deleteCoupon.fulfilled, (state, action) => {
            state.isLoading = false;
        })
           builder.addCase(deleteCoupon.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(updateSocialMedia.fulfilled, (state, action) => {
            state.isLoading = false;
        })
        builder.addCase(updateSocialMedia.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchTotalEarning.fulfilled, (state, action) => {
            state.totalEarning =  action.payload
            state.isLoading = false;
        })
        builder.addCase(fetchTotalEarning.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchPendingOrders.fulfilled, (state, action) => {
            state.pendingOrders =  action.payload
            state.isLoading = false;
        })
        builder.addCase(fetchPendingOrders.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchAvgDailySales.fulfilled, (state, action) => {
            state.averageDailySales =  action.payload
            state.isLoading = false;
        })
        builder.addCase(fetchAvgDailySales.pending, (state, action) => {
            state.isLoading = true;
        })
        
        builder.addCase(fetchAllOrders.fulfilled, (state, action) => {
            state.allOrders =  action.payload
            state.isLoading = false;
        })
        builder.addCase(fetchAllOrders.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchMoneyBackGurante.fulfilled, (state, action) => {
            state.moneyBackGurante =  action.payload
            state.isLoading = false;
        })
        builder.addCase(fetchMoneyBackGurante.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(updateMoneyBackGurante.fulfilled, (state, action) => {
            state.isLoading = false;
            state.updateAlert = true;
        })
        builder.addCase(updateMoneyBackGurante.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchAboutUs.fulfilled, (state, action) => {
            state.aboutUs =  action.payload
            state.isLoading = false;
        })
        builder.addCase(fetchAboutUs.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(updateAboutUs.fulfilled, (state, action) => {
            state.isLoading = false;
            state.updateAlert = true;
        })
        builder.addCase(updateAboutUs.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchContactUs.fulfilled, (state, action) => {
            state.contactUs =  action.payload
            state.isLoading = false;
        })
        builder.addCase(fetchContactUs.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(updateContactUs.fulfilled, (state, action) => {
            state.isLoading = false;
            state.updateAlert = true;
        })
        builder.addCase(updateContactUs.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchGeneralSetting.fulfilled, (state, action) => {
            state.generalSetting =  action.payload
            state.isLoading = false;
        })
        builder.addCase(fetchGeneralSetting.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchReturnPolicy.fulfilled, (state, action) => {
            state.returnPolicy =  action.payload
            state.isLoading = false;
        })
        builder.addCase(fetchReturnPolicy.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(updateReturnPolicy.fulfilled, (state, action) => {
            state.isLoading = false;
            state.updateAlert = true;
        })
        builder.addCase(updateReturnPolicy.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchSafeShopping.fulfilled, (state, action) => {
            state.safeShopping =  action.payload
            state.isLoading = false;
        })
        builder.addCase(fetchSafeShopping.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(updateSafeShopping.fulfilled, (state, action) => {
            state.isLoading = false;
            state.updateAlert = true;
        })
        builder.addCase(updateSafeShopping.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(UpdateGeneralSetting.fulfilled, (state, action) => {
            state.isLoading = false;
        })
        builder.addCase(UpdateGeneralSetting.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchAppStore.fulfilled, (state, action) => {
            state.appStore = action.payload
            state.isLoading = false;
        })
        builder.addCase(fetchAppStore.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(UpdateAppStore.fulfilled, (state, action) => {
            state.isLoading = false;
        })
        builder.addCase(UpdateAppStore.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchSocialMedia.fulfilled, (state, action) => {
            state.socialMedia = action.payload
            state.isLoading = false;
        })
        builder.addCase(fetchSocialMedia.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(editSocialMedia.fulfilled, (state, action) => {
            state.editSocialMedia = action.payload
            state.isLoading = false;
        })
        builder.addCase(editSocialMedia.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchSlider.fulfilled, (state, action) => {
            state.slider = action.payload
            state.isLoading = false;
        })
        builder.addCase(fetchSlider.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(editSlider.fulfilled, (state, action) => {
            state.editSlider = action.payload
            state.isLoading = false;
        })
        builder.addCase(editSlider.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchTopCountries.fulfilled, (state, action) => {
            state.topCountries = action.payload
            state.isLoading = false;
        })
        builder.addCase(fetchTopCountries.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchSearchProducts.fulfilled, (state, action) => {
            state.productList = action.payload;
            state.isLoading = false;
        })
        builder.addCase(fetchSearchProducts.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchSearchCoupon.fulfilled, (state, action) => {
            state.coupon = action.payload;
            state.isLoading = false;
        })
        builder.addCase(fetchSearchCoupon.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchSearchReview.fulfilled, (state, action) => {
            state.reviews = action.payload;
            state.isLoading = false;
        })
        builder.addCase(fetchSearchReview.pending, (state, action) => {
            state.isLoading = true;
        })
        
        builder.addCase(fetchTotalRevenue.fulfilled, (state, action) => {
            state.totalRevenue = action.payload;
        })
               
        builder.addCase(fetchDailyTraffic.fulfilled, (state, action) => {
            state.dailyTraffic = action.payload;
        })
               
        builder.addCase(updateMainCate.fulfilled, (state, action) => {
            state.updateAlert = true;
        })
        builder.addCase(updateSubCate.fulfilled, (state, action) => {
            state.updateAlert = true;
        })
        
        builder.addCase(postSocialMedia.fulfilled, (state, action) => {
           state.createAlert = true
           state.isLoading = false
        })
        builder.addCase(postSocialMedia.pending, (state, action) => {
            state.isLoading = true
         })
         builder.addCase(deleteSocialMedia.fulfilled, (state, action) => {
            state.deleteAlert = true
            state.isLoading = false
         })
         builder.addCase(deleteSocialMedia.pending, (state, action) => {
            state.isLoading = true
         })
         builder.addCase(postSlider.fulfilled, (state, action) => {
            state.createAlert = true
            state.isLoading = false
         })
         builder.addCase(postSlider.pending, (state, action) => {
             state.isLoading = true
          })
          builder.addCase(postMainCategory.fulfilled, (state, action) => {
            state.createAlert = true
            state.isLoading = false
         })
         builder.addCase(postMainCategory.pending, (state, action) => {
             state.isLoading = true
          })
    }
})
export const {hideUpdateAlert , hideCreateAlert , hideDeleteAlert, userDobaraSetKr , logout} = globalState.actions;
export default globalState;