import Card from "components/card";

import React, { useMemo } from "react";
import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";

const OrderDetailTable = (props) => {
    const { columnsData, tableData } = props;

    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData || [], [tableData]);
    const tableInstance = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        initialState,
    } = tableInstance;
    initialState.pageSize = 11;

    return (
        <Card extra={"w-full h-full p-4"}>
            <div class="h-full overflow-x-auto">
                <table
                    {...getTableProps()}
                    className="mt-8 h-max w-full"
                    variant="simple"
                    color="gray-500"
                    mb="24px"
                >
                    <thead>
                        {headerGroups.map((headerGroup, index) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                {headerGroup.headers.map((column, index) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className="border-b border-gray-300 uppercase pb-[10px] text-start dark:!border-navy-700 "
                                        key={index}
                                    >
                                        <div className="text-xs font-bold tracking-wide text-gray-600">
                                            {column.render("Header")}
                                        </div>
                                    </th>
                                ))}
                                
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, index) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} key={index}>
                                    {row.cells.map((cell, index) => {
                                        let data = "";
                                        if (cell.column.Header === "IMAGE") {
                                            data = (
                                                <img src={cell?.value} alt="" className=" w-24 object-contain"/>
                                            );
                                        } 
                                        else if (cell.column.Header === "TITLE") {
                                            data = (
                                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                    {cell?.value}
                                                </p>
                                            );
                                        } 
                                        else if (cell.column.Header === "PRICE") {
                                            data = (
                                                <div className="flex items-center gap-3">
                                                    <p><p className="text-sm font-bold text-navy-700 dark:text-white">
                                                        {cell.value}
                                                    </p></p>
                                                </div>
                                            );
                                        } else if (cell.column.Header === "QUANTITY") {
                                            data = (
                                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                    {cell.value}
                                                </p>
                                            );
                                        } 
                                         else if (cell.column.Header === "ITEM SUBTOTAL") {
                                            data = (
                                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                    {cell.value}
                                                </p>
                                            );
                                        } 
                                         else if (cell.column.Header === "INSTOCK") {
                                            data = (
                                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                    {cell.value}
                                                </p>
                                            );
                                        } 
                                        return (

                                            <td
                                                {...cell.getCellProps()}
                                                key={index}
                                                className="pt-[14px] pb-3 text-[14px]"
                                            >
                                                {data}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </Card>
    );
};

export default OrderDetailTable;