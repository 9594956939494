
import React, { useCallback, useEffect, useState } from 'react'
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useDispatch } from 'react-redux';
import { updateAboutUs } from 'store/globalState';

function AboutUs(props) {
  const dispatch = useDispatch();
    const aboutData = props.data;
    const [aboutUsData, setAboutUsData] = useState(aboutData?.aboutus)
    const [aboutusImg, setAboutUsImg] = useState("")
    useEffect(() => {
      setAboutUsData(aboutData?.aboutus);
  }, [aboutData]);
    useEffect(() => {
       if(aboutData)
       {
        const imageUrl = aboutData?.aboutus?.images?.[0]?.url || "";
        setAboutUsImg(imageUrl);
       }
    },[aboutData])
  
    const [editorState, setEditorState] = useState(
      EditorState.createEmpty() // Initialize with empty content
    );
      
    const [editorStat, setEditorStat] = useState(
      EditorState.createEmpty() // Initialize with empty content
    );
  
    useEffect(() => {
      if (aboutUsData?.shortText && editorState.getCurrentContent().hasText() === false) {
          const blocksFromHTML = convertFromHTML(aboutUsData.shortText);
          const contentState = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap
          );
          setEditorState(EditorState.createWithContent(contentState));
      }
  }, [aboutUsData]);

     
  useEffect(() => {
    if (aboutUsData?.longText && editorStat.getCurrentContent().hasText() === false) {
        const blocksFromHTML = convertFromHTML(aboutUsData.longText);
        const contentState = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        );
        setEditorStat(EditorState.createWithContent(contentState));
    }
}, [aboutUsData]);


const paraStateChange = (editorState) => {
  const rawContent = convertToRaw(editorState.getCurrentContent());
  const updatedShortText = draftToHtml(rawContent);
  setEditorState(editorState);
  setAboutUsData(prevData => ({
      ...prevData,
      shortText: updatedShortText
  }));
};

const descStateChange = (editorState) => {
  const rawContent = convertToRaw(editorStat.getCurrentContent());
  const updatedLongText = draftToHtml(rawContent);
  setEditorStat(editorState);
  setAboutUsData(prevData => ({
      ...prevData,
      longText: updatedLongText
  }));
};

    const updateAboutusData = () => {
      dispatch(updateAboutUs(aboutUsData))
    }
  return (
    <div>
    <div className="col-span-6">
      <label
        htmlFor=""
        className={`text-sm text-navy-700 dark:text-white ml-1.5 font-medium`}
      >
        About Us Image*
      </label>
      {
        aboutusImg ?
          <div class="flex items-center justify-center w-full mt-3">
        
            <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
              <div class="flex flex-col items-center justify-center w-full h-full">
                <img src={aboutusImg} alt="" className='w-full object-contain h-full' crossOrigin='anonymous'/>
              </div>
              <input id="dropzone-file" type="file" class="hidden" accept="image/png, image/jpeg" />
            </label>
          </div>
          :
          <div class="flex items-center justify-center w-full mt-3">
            <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
              <div class="flex flex-col items-center justify-center w-full h-full">
                <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                <p class="text-xs text-gray-500 dark:text-gray-400">PNG or JPG</p>
              </div>
              <input id="dropzone-file" type="file" class="hidden" accept="image/png, image/jpeg" />
            </label>
          </div>
      }

    </div>
    <div className="col-span-12 mt-7">
      <label
        htmlFor=""
        className={`text-sm text-navy-700 dark:text-white ml-1.5 font-medium block mb-3`}
      >
        Short Paragraph*
      </label>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="border border-gray-400 rounded-lg"
        editorClassName="px-3"
        onEditorStateChange={paraStateChange}
        toolbar={{
          options: ['inline', 'link', 'list']
        }}
      />
    </div>
    <div className="col-span-12 mt-7">
      <label
        htmlFor=""
        className={`text-sm text-navy-700 dark:text-white ml-1.5 font-medium block mb-3`}
      >
        Description*
      </label>
      <Editor
        editorState={editorStat}
        toolbarClassName="toolbarClassName"
        wrapperClassName="border border-gray-400 rounded-lg"
        editorClassName="px-3"
        onEditorStateChange={descStateChange}
        toolbar={{
          options: ['inline', 'link', 'list']
        }}
      />
    </div>
    <div className="flex justify-end mt-7">
      <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={updateAboutusData}>
        Update
      </button>
      
    </div>
  </div>
 
  )
}

export default AboutUs