import React, { useEffect, useState } from 'react'
import InputField from 'components/fields/InputField'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import { editSlider } from 'store/globalState';
import { uploadSlider } from 'store/globalState';
import { updateSlider } from 'store/globalState';

function EditSlider() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const data = useSelector((state) => state.global_state.editSlider?.slider);
    const [editData , setEditData] = useState(data || "");
    const uploadSliderImg = useSelector((state) => state.global_state.uploadSliderImg);
    const [sliderImg , setSliderImg] = useState('');
    useEffect(() => {
        if(uploadSliderImg) {
            setSliderImg(uploadSliderImg)
            setEditData({...data , image: {url : uploadSliderImg}})
        }
    }, [uploadSliderImg])

    useEffect(() => {
        if (data) {
            setSliderImg(data?.image?.url)
            setEditData(data)
        }

    }, [data])
    useEffect(() => {
        dispatch(editSlider(id))
    },[dispatch, id])
    const handleChange = (event) => {
        setEditData({
            ...editData,
            [event.target.name] : event.target.value
        })
    }
    const handleSubmit = (event) => {
        console.log("editData", editData)
        event.preventDefault();
        dispatch(updateSlider(editData))
    }

    const uploadSliderImage = (event) => {
        const file = event?.target?.files[0];
        if(file) {
            const formData = new FormData();
            formData.append('image', file);
            dispatch(uploadSlider(formData))
        }
    }
    return (
        <div className='w-full h-full'>
            <form onSubmit={handleSubmit}>
            <div class="w-full mt-3">
              <h4  className={"text-sm text-navy-700 dark:text-white ml-1.5 font-medium text-center w-full mb-3"}>Logo*</h4>

                {
                    sliderImg ? 
                        <label onChange={uploadSliderImage} for="dropzone-filessss" class="flex flex-col items-center justify-center w-full  h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                            <div class="flex flex-col items-center justify-center w-full h-full">
                                {
                                    <img src={sliderImg} alt="" className='w-full object-contain h-full' />
                                }
                            </div>
                            <input id="dropzone-filessss" type="file" class="hidden" accept="image/png, image/jpeg" />
                        </label>
                        :
                        <label for="dropzone-filessss" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                            <div class="flex flex-col items-center justify-center w-full h-full">
                                {
                                    <label for="dropzone-filessss" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                        <div class="flex flex-col items-center justify-center w-full h-full">
                                            <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                                            <p class="text-xs text-gray-500 dark:text-gray-400">PNG or JPG</p>
                                        </div>
                                        <input id="dropzone-filessss" type="file" class="hidden" accept="image/png, image/jpeg" />
                                    </label>
                                }
                            </div>
                            <input id="dropzone-file" type="file" class="hidden" accept="image/png, image/jpeg" />
                        </label>
                }
            </div>
            <div className='grid grid-cols-2 gap-4 w-full mt-5'>
                <InputField
                    variant="auth"
                    extra="mb-3 w-full pr-2"
                    label="Heading*"
                    placeholder="Enter Heading .."
                    id="heading"
                    type="text"
                    name="text_two"
                    value={editData?.text_two || ""}
                    onChange={handleChange}
                />
                <InputField
                    variant="auth"
                    extra="mb-3 w-full pr-2"
                    label="Sub Heading*"
                    placeholder="Enter Sub Heading.."
                    id="subHeading"
                    type="text"
                    name="text_one"
                    value={editData?.text_one || ""}
                    onChange={handleChange}
                />
             
            </div>
            <div className="flex justify-end mt-7">
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" >
                    Update
                </button>
            </div>
            </form>
        </div>
    )
}

export default EditSlider





