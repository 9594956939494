import React, { useEffect, useState } from 'react'
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useDispatch } from 'react-redux';
import { updateMoneyBackGurante } from 'store/globalState';

function MoneyBackGurrante(props) {
    const data = props.data;
    const dispatch = useDispatch();
    const [moneyBackGurrante, setMoneyBackGurrante] = useState(data?.moneybackGuarantee || {})
    const [description, setDescription] = useState(null)
    useEffect(() => {
      if (data) {
          setDescription(data?.moneybackGuarantee?.text || "");
      }
  }, [data]);
  
  useEffect(() => {
      setMoneyBackGurrante(data?.moneybackGuarantee || {});
  }, [data]);
    const [editorState, setEditorState] = useState(
      EditorState.createEmpty() // Initialize with empty content
    );
  
    useEffect(() => {
      // Initialize with a predefined content
      if(moneyBackGurrante?.text && editorState.getCurrentContent().hasText() === false)
      {
        const blocksFromHTML = convertFromHTML(moneyBackGurrante?.text);
        const contentState = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        );
        setEditorState(EditorState.createWithContent(contentState));
      }
  
    }, [description]);
  
    const descStateChange = (editorState) => {
      const rawContent = convertToRaw(editorState.getCurrentContent());
      const updatedText = draftToHtml(rawContent);
      setEditorState(editorState);
      setMoneyBackGurrante(prevData => ({
        ...prevData,
        text: updatedText
    }));
    };

    const updateMoneyBackData = () => {
      dispatch(updateMoneyBackGurante(moneyBackGurrante))
    }
  
  return (
    <div>
            <div className="col-span-12 mt-7">
              <label
                htmlFor=""
                className={`text-sm text-navy-700 dark:text-white ml-1.5 font-medium block mb-3`}
              >
                Description*
              </label>
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="border border-gray-400 rounded-lg"
                editorClassName="px-3"
                onEditorStateChange={descStateChange}
                toolbar={{
                  options: ['inline', 'link', 'list']
                }}
              />
            </div>
            <div className="flex justify-end mt-7">
      <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={updateMoneyBackData}>
        Update
      </button>
    </div>
    </div>
  )
}

export default MoneyBackGurrante