import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { useDispatch, useSelector } from 'react-redux';
import { fetchAboutUs } from 'store/globalState';
import AboutUs from 'components/CMS_Pages/AboutUs';
import { fetchContactUs } from 'store/globalState';
import ContactUs from 'components/CMS_Pages/ContactUs';
import MoneyBackGurrante from 'components/CMS_Pages/MoneyBackGurrante';
import { fetchMoneyBackGurante } from 'store/globalState';
import SafeShopping from 'components/CMS_Pages/SafeShopping';
import ReturnPolicy from 'components/CMS_Pages/ReturnPolicy';
import { fetchReturnPolicy } from 'store/globalState';
import { fetchSafeShopping } from 'store/globalState';



function Cms() {
  const dispatch = useDispatch();
  const aboutUS = useSelector((state) => state.global_state?.aboutUs);
  const contactUs = useSelector((state) => state.global_state?.contactUs);
  const moneyBackGurante = useSelector((state) => state.global_state?.moneyBackGurante);
  const safeShopping = useSelector((state) => state.global_state?.safeShopping);
  const returnPolicy = useSelector((state) => state.global_state?.returnPolicy);
  useEffect(() => {
    dispatch(fetchAboutUs());
    dispatch(fetchContactUs());
    dispatch(fetchMoneyBackGurante());
    dispatch(fetchSafeShopping());
    dispatch(fetchReturnPolicy());
  }, [dispatch])


  return (
    <div className='mt-[2.25rem] h-full w-full'>
      <Tabs>
        <TabList>
          <Tab>About Us</Tab>
          <Tab>Contact Us</Tab>
          <Tab>Moneyback Guarante</Tab>
          <Tab>Safe Shopping</Tab>
          <Tab>Return Policy</Tab>
          <Tab>Shipping</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
           <AboutUs data={aboutUS}/>
          </TabPanel>
          <TabPanel>
          <ContactUs data={contactUs}/>
          </TabPanel>
          <TabPanel>
           <MoneyBackGurrante data={moneyBackGurante}/>
          </TabPanel>
          <TabPanel>
           <SafeShopping data={safeShopping}/>
          </TabPanel>
          <TabPanel>
           <ReturnPolicy data={returnPolicy}/>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  )
}

export default Cms