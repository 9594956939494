import PieChart from "components/charts/PieChart";
import Card from "components/card";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTopCountries } from "store/globalState";

const PieChartCard = () => {
  const dispatch = useDispatch();
  const topCountries = useSelector((state) => state.global_state.topCountries?.data);
  const [countNames, setCountNames] = useState([]);
  const [countData, setCountData] = useState([]);
  useEffect(() => {
    dispatch(fetchTopCountries())
  }, [dispatch])
  const [pieChartCountries, setPieChartCountries] = useState({})

  useEffect(() => {
    if (countNames?.length > 0) {
      setPieChartCountries({
        labels: [countNames[0], countNames[1], countNames[2]],
        colors: ["#4318FF", "#6AD2FF", "#22c55e"],
        chart: {
          width: "50px",
        },
        states: {
          hover: {
            filter: {
              type: "none",
            },
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        hover: { mode: null },
        plotOptions: {
          donut: {
            expandOnClick: false,
            donut: {
              labels: {
                show: false,
              },
            },
          },
        },
        fill: {
          colors: ["#4318FF", "#6AD2FF", "#22c55e"],
        },
        tooltip: {
          enabled: true,
          theme: "dark",
          style: {
            fontSize: "12px",
            fontFamily: undefined,
            backgroundColor: "#000000"
          },
        },
      })
    }

  }, [topCountries,countNames])

  useEffect(() => {
    setCountNames(topCountries?.map((val) => val?.countryName))
  }, [topCountries])

  useEffect(() => {
    setCountData(topCountries?.map((val) => val?.totalOrders))
  }, [topCountries])
  return (
    <Card extra="rounded-[20px] p-3">
      <div className="flex flex-row justify-between px-3 pt-2">
        <div>
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            Top 3 Countries
          </h4>
        </div>
      </div>

      <div className="mb-auto flex h-[220px] w-full items-center justify-center">
        <PieChart options={pieChartCountries} series={countData} />
      </div>
      <div className="flex flex-row !justify-between rounded-2xl px-6 py-3 shadow-2xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">

        {
          topCountries?.map((val, indx) => {
            return <> <div className="flex flex-col items-center justify-center" key={indx}>
              <div className="flex items-center justify-center">
                <div className="h-2 w-2 rounded-full bg-brand-500" />
                <p className="ml-1 text-sm font-normal text-gray-600">{val?.countryName}</p>
              </div>
              <p className="mt-px text-xl font-bold text-navy-700  dark:text-white">
                {val?.totalOrders}%
              </p>
            </div>


              {indx !== topCountries.length - 1 && (
                <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />
              )}
            </>
          })
        }
      </div>
    </Card>
  );
};

export default PieChartCard;
