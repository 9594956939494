import React, { useEffect, useState } from 'react'
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { updateReturnPolicy } from 'store/globalState';
import { useDispatch } from 'react-redux';


function ReturnPolicy(props) {
    const data = props.data;
    const dispatch = useDispatch()
    const [returnPolicy, setReturnPolicy] = useState(data?.returnPolicy || "")
    const [description, setDescription] = useState(null)
    useEffect(() => {
      if (data) {
          setDescription(data?.returnPolicy?.text || "");
      }
  }, [data]);

  useEffect(() => {
      setReturnPolicy(data?.returnPolicy || "");
  }, [data]);
    const [editorState, setEditorState] = useState(
        EditorState.createEmpty() // Initialize with empty content
      );
    
      useEffect(() => {
        // Initialize with a predefined content
        if(returnPolicy?.text  && editorState.getCurrentContent().hasText() === false)
        {
          const blocksFromHTML = convertFromHTML(returnPolicy?.text );
          const contentState = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap
          );
          setEditorState(EditorState.createWithContent(contentState));
        }
    
    
      }, [description]);
    
      const descStateChange = (editorState) => {
        const rawContent = convertToRaw(editorState.getCurrentContent());
        const updatedText = draftToHtml(rawContent);
        setEditorState(editorState);
        setReturnPolicy(prevData => ({
          ...prevData,
          text: updatedText
      }));
      };

      const updateReturnPolicyData = () => {
        dispatch(updateReturnPolicy(returnPolicy))
      }
    return (
        <div>
            <div className="col-span-12 mt-7">
                <label
                    htmlFor=""
                    className={`text-sm text-navy-700 dark:text-white ml-1.5 font-medium block mb-3`}
                >
                    Description*
                </label>
                <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="border border-gray-400 rounded-lg"
                    editorClassName="px-3"
                    onEditorStateChange={descStateChange}
                    toolbar={{
                        options: ['inline', 'link', 'list']
                    }}
                />
            </div>
            <div className="flex justify-end mt-7">
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={updateReturnPolicyData}>
                    Update
                </button>
            </div>
        </div>
    )
}

export default ReturnPolicy