import InputField from 'components/fields/InputField'
import React, { useEffect, useState } from 'react'
import { Tabs, TabList, TabPanels, Tab, TabPanel, FormControl, FormLabel, Checkbox } from '@chakra-ui/react'
import { Select } from '@chakra-ui/react'
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState ,convertFromRaw , convertToRaw } from 'draft-js';
import { ImCross } from 'react-icons/im'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCategories } from 'store/globalState'
import { uploadNewImage } from 'store/globalState'
import { uploadMultipleImages } from 'store/globalState'
import { createProduct } from 'store/globalState'
import { useNavigate } from 'react-router-dom'
import { fetchProductList } from 'store/globalState'

function CreateProduct() {
    const dispatch = useDispatch();
    const categories = useSelector((state) => state.global_state.categories?.categories);
    const [subCategories, setSubCategories] = useState([]);
    const navigate = useNavigate();
    const [mainCategories, setMainCategories] = useState([]);
    const productImages = useSelector((state) => state.global_state.productImages);
    const mainImageGlobal = useSelector((state) => state.global_state.mainImageUrl);
    const [images, setImages] = useState([]);
    const [main_image , setMainImage] = useState({url: ''});
    const [createProdData, setCreateProdData] = useState({
        title: '',
        price: 0,
        comparePrice: 0,
        quantity_in_stock: 0,
        featured: false,
        parent_category: "",
        category: '',
        description: '',
    });

    let initialContent = {
        entityMap: {},
        blocks: [
            {
                key: '1',
                text: createProdData?.description || "",
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
                data: {}
            }
        ]
    };

    const [editorState, setEditorState] = useState(() => {
        const contentState = convertFromRaw(initialContent);
        return EditorState.createWithContent(contentState);
    });

    const EditorStateChange = (editorState) => {
        setEditorState(editorState);
        setCreateProdData({
            ...createProdData,
            description: convertToRaw(editorState.getCurrentContent())?.blocks[0]?.text
        })
    };

    useEffect(() => {
        if (categories?.length > 0) {
            setMainCategories(categories)
            const subCats = mainCategories?.map(value => {
                return value?.category
            })
            setSubCategories(...subCats)
        }


    }, [categories, mainCategories])

    const onSelectCategory = (event) => {
        const subCats = mainCategories?.map((value, i) => {
            if (value?._id === event?.target?.value) {
                return value?.category
            }
            return null
        }).filter(val => val)
        setSubCategories(...subCats)
        setCreateProdData({
            ...createProdData,
            [event.target.name]: event.target.value,
            category: subCats[0][0].name
        })
    }

    useEffect(() => {
        dispatch(fetchCategories())
    }, [dispatch])

    const handleChange = (event) => {
        setCreateProdData({
            ...createProdData,
            [event.target.name]: event.target.value
        });
    }

    const subCatChange = (event) => {
        setCreateProdData({
            ...createProdData,
            [event.target.name]: event.target.value
        })
    }

    const featuredChecked = (event) => {
        setCreateProdData({
            ...createProdData,
            [event.target.name]: event.target.checked
        })
    }

    useEffect(() => {
        setImages(createProdData?.images);
        setMainImage(createProdData?.main_image?.url);
        setCreateProdData(createProdData);
    }, [createProdData])

    useEffect(() => {
        if(mainImageGlobal) {
            setMainImage({url: mainImageGlobal})
            setCreateProdData(Object.assign(createProdData,  {main_image: {url : mainImageGlobal}} ))
        }
    }, [createProdData, mainImageGlobal])
    
    const removeImage = (i) => 
    {
        let copyImg = [...images];
        copyImg.splice(i,1);
        setImages(copyImg);
        setCreateProdData(Object.assign(createProdData, {images: copyImg} ))
    }

    const addNewImage = (event) => {
        const file = event?.target?.files[0];
        if(file) {
            const formData = new FormData();
            formData.append('image', file);
            dispatch(uploadNewImage(formData))
        }
    }

    const addProductImages = (event) => {
        const files = event?.target?.files;
        if(files?.length > 0) {
            const formData = new FormData();
            Array.from(files).forEach(value => {
                formData.append('images', value)
            })
            dispatch(uploadMultipleImages(formData))
        }
    }

    useEffect(() => {
        if(productImages?.length > 0) {
            if(images?.length > 0) {
                setImages([...images, ...productImages]);
                setCreateProdData(Object.assign(createProdData, {images: [...images, ...productImages] } ))
            }
            else {
                setImages(productImages);
                setCreateProdData(Object.assign(createProdData, {images: productImages } ))
            }
        }
    }, [productImages])

    const createData = async () => {
       await dispatch(createProduct(createProdData))
       await Navigate();
    }

    const Navigate = async () => {
        navigate('/admin/products')
        await dispatch(fetchProductList());
    }
    return (
        <div className="mt-[2.25rem] h-full w-full flex justify-between">
            <Tabs className="bg-white shadow-md rounded-md w-full px-4 py-4">
                <TabList>
                    <Tab>Add New Product</Tab>
                    <Tab>Bulk Upload</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <div className='grid grid-cols-2 gap-4 w-full'>
                            <InputField
                                variant="auth"
                                extra="mb-3 w-full pr-2"
                                label="Title*"
                                placeholder="Enter Title.."
                                id="title"
                                type="text"
                                name="title"
                                value={setCreateProdData.title}
                                onChange={handleChange}
                            />
                            <FormControl className='w-full'>
                                <FormLabel htmlFor="categories" fontSize="sm" fontWeight="medium" className='ml-1'>Main Category</FormLabel>
                                <Select onChange={(event) => onSelectCategory(event)} name='parent_category' id="categories" className='w-full mt-0.5' height='49px' borderRadius='12px'>
                                <option disabled selected className='text-gray-400'>- Select Main Category -</option>
                                    {
                                        mainCategories?.map((mcategory, index) => {

                                            return <option value={mcategory?._id} key={index}>{mcategory?.parent_category}</option>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className='grid grid-cols-2 gap-4 w-full'>
                            <FormControl className='w-full'>
                                <FormLabel htmlFor="categories" fontSize="sm" fontWeight="medium" className='ml-1'>Sub Category</FormLabel>
                                <Select id="categories" onChange={subCatChange} name='category' className='w-full mt-0.5' height='49px' borderRadius='12px'>
                                    {subCategories?.map((value, index) => {
                                        return <option key={index} value={value?.name} name="category">{value?.name}</option>
                                    })}
                                </Select>
                            </FormControl>
                            <InputField
                                variant="auth"
                                extra="mb-3 w-full"
                                label="Price*"
                                placeholder="Enter Price.."
                                id="price"
                                type="text"
                                name="price"
                                value={setCreateProdData.price}
                                onChange={handleChange}
                            />


                        </div>
                        <div className='flex gap-x-6 w-full'>
                            <InputField
                                variant="auth"
                                extra="mb-3 w-49p"
                                label="Discount Price*"
                                placeholder="Enter Discount Price.."
                                id="discount price"
                                type="text"
                                name="comparePrice"
                                value={setCreateProdData.comparePrice}
                                onChange={handleChange}
                            />
                            <InputField
                                variant="auth"
                                extra="mb-3 w-49p"
                                label="Stock*"
                                placeholder="Enter Stock.."
                                id="stock"
                                type="text"
                                name="quantity_in_stock"
                                value={setCreateProdData.quantity_in_stock}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='grid grid-cols-1 mb-5'>
                            <Checkbox name='featured' onChange={featuredChecked} size='lg' colorScheme='green' className='mt-5' defaultChecked={setCreateProdData.featured}>
                                Feature this Product?
                            </Checkbox></div>
                        <div className='grid grid-cols-12 gap-4'>
                            <div className="col-span-12">
                                <label
                                    htmlFor=""
                                    className={`text-sm text-navy-700 dark:text-white ml-1.5 font-medium block mb-3`}
                                >
                                    Description*
                                </label>
                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="border border-gray-400 rounded-lg"
                                    editorClassName="px-3"
                                    onEditorStateChange={EditorStateChange}
                                    toolbar={{
                                        options: ['inline', 'link', 'list']
                                    }}
                                />
                            </div>
                        </div>
                        <div className=' mt-5'>
                <div className="col-span-6">
                    <label
                        htmlFor=""
                        className={`text-sm text-navy-700 dark:text-white ml-1.5 font-medium`}
                    >
                        Cover Image*
                    </label>
                    <div onChange={addNewImage} class="flex items-center justify-center w-full mt-3">
                        <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                            <div class="flex flex-col items-center justify-center w-full h-full">
                                {
                                    main_image?.url ? 
                                    <img className='w-40 object-contain h-40' crossOrigin='anonymous' src={main_image?.url} alt='img'/>
                                    :
                                    <div>
                                        <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                                        <p class="text-xs text-gray-500 dark:text-gray-400">PNG or JPG</p>
                                    </div>
                                }
                            </div>
                            <input id="dropzone-file" type="file" class="hidden" accept="image/png, image/jpeg"/>
                        </label>
                    </div>

                </div>
            </div>
            <div className='mt-5'>
                <label
                    htmlFor=""
                    className={`text-sm text-navy-700 dark:text-white ml-1.5 font-medium`}
                >
                    Product Images*
                </label>

                <div onChange={addProductImages} class="flex items-center justify-center w-full mt-3">
                    <label for="dropzone-files" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                    <div class="flex gap-4 w-full h-full mt-3 ml-3" >
                        {
                            images?.map((img, index) => {
                                return <div className='relative' key={index}>
                                    <img crossOrigin='anonymous' src={img?.url} alt="looped_images" className='w-24 object-contain h-24 rounded-lg' />
                                    <div className=' absolute top-0 -right-1 rounded-full p-1 bg-red-600'>
                                        <ImCross className='text-[10px] text-white'  onClick={(event) => {event.preventDefault(); removeImage(index)}}/>
                                    </div>
                                </div>
                            })
                        }
                        </div>
                        <input id="dropzone-files" type="file" class="hidden" multiple/>
                    </label>
                </div>
            </div>
            <div className="flex justify-end mt-5">
                <div className="relative">
                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 mr-2 rounded" onClick={createData}>
                        Create
                    </button>
                   
                </div>
            </div>
                    </TabPanel>
                    <TabPanel>

                        <div className="h-full w-full rounded-xl bg-lightPrimary dark:!bg-navy-700 mt-2">
                        <div onChange={addNewImage} class="flex items-center justify-center w-full mt-3">
                        <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                            <div class="flex flex-col items-center justify-center w-full h-full">
                                {
                                    main_image?.url ? 
                                    <img className='w-40 object-contain h-40' crossOrigin='anonymous' src={main_image?.url} alt='jani'/>
                                    :
                                    <div>
                                        <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                                        <p class="text-xs text-gray-500 dark:text-gray-400">PNG or JPG</p>
                                    </div>
                                }
                            </div>
                            <input id="dropzone-file" type="file" class="hidden" accept="file/xlsx"/>
                        </label>
                    </div>
                        </div>
                    </TabPanel>
                </TabPanels>
            </Tabs>

        </div>
    )
}

export default CreateProduct