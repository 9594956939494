import { environment } from './environments';
import axios, { AxiosHeaders } from 'axios';

// Header Config
export const headersConfig = {
  'LOCALE': 'en',
  'Accept': 'application/json',
  'Access-Control-Allow-Origin': '*'
};

// Utility functions
const objectToQueryString = (obj) => {
    const str = [];
    for (const p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return str.join('&');
}

//set Headers
export const setHeaders = () => {
  const header = {
    ...headersConfig,
    'Content-Type': 'application/json',
  };
  return new AxiosHeaders(header);
}

const mapAndCatchError = async (serverCall) => {
  return await serverCall.then(serverResponse => {
    const response = new ApiResponse();
    Object.assign(response, serverResponse)
    delete response?.headers
    delete response?.config
    delete response?.request
    return response
  })
  .catch(err => {
    const response = new ApiResponse();
    const errObj = {
      code : err?.code,
      message : err?.response?.data?.message,
      status : err?.response?.status
    }
    response.errors.push(errObj);
    Object.assign(response, err);
    delete response?.config;
    delete response?.name;
    delete response?.request;
    return response;
  }) 
}

//REST Methods
export const get = async (path, params) => {
  const options = {
    params: params ? {...params} : null,
    headers: setHeaders(),
    method: "GET"
  };
  return await mapAndCatchError(axios.get(`${environment.apiUrl}/${path}`, options));
}

// POST
export const post = async(
  path,
  body = {}
  ) => {
  return await mapAndCatchError(axios.post(`${environment.apiUrl}/${path}`, body))
}

// DELETE
export const del = async(
  path,
  body
  ) => {
  return await mapAndCatchError(axios.delete(`${environment.apiUrl}/${path}`, {data : body}))
}

//Post Media

export const postMedia = async(
  path,
  body = {}
  ) => {
  return await mapAndCatchError(axios.post(`${environment.apiUrl}/${path}`, body, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  )
}

//PUT
export const put =  async(
path,
body = {}
) => {
return await mapAndCatchError(axios.put(`${environment.apiUrl}/${path}`, body))
}

// PATCH
export const patch = async(
  path,
  body = {}
  ) =>  {
    return await mapAndCatchError(axios.patch(`${environment.apiUrl}/${path}`, body))
}

//class for api response

class ApiResponse {
  constructor() {
    this.errors = [];
  }
  status; //boolean
  data; // response data
  getErrorsText() {
    return this.errors.map((e) => e.text).join(' ');
  }
  hasErrors() {
    return this.errors.length > 0;
  }
}