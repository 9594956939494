import React, { useEffect, useState } from 'react'
import InputField from 'components/fields/InputField'
import { useDispatch, useSelector } from 'react-redux'
import { editSocialMedia } from 'store/globalState';
import { useParams } from 'react-router-dom';
import { fetchSocialMedia } from 'store/globalState';
import { updateSocialMedia } from 'store/globalState';

function EditSocialMedia() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const data = useSelector((state) => state.global_state.editSocialMedia?.socialMedia);
    const [editData , setEditData] = useState(data || "");
    const socialMediaImg = useSelector((state) => state.global_state.socialMediaImg);
    const [socialMedia , setSocialMedia] = useState('');
     console.log("editData", editData)
    useEffect(() => {
        if(socialMediaImg) {
            setSocialMedia(socialMediaImg)
            setEditData({...data , image: {url : socialMediaImg}})
        }
    }, [socialMediaImg])

    useEffect(() => {
        if (data) {
            setSocialMedia(data?.image?.url)
            setEditData(data)
        }

    }, [data])
    useEffect(() => {
        dispatch(editSocialMedia(id))
    },[dispatch, id])
    const handleChange = (event) => {
        setEditData({
            ...editData,
            [event.target.name] : event.target.value
        })
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            id : id,
            formData : editData
        }
        dispatch(updateSocialMedia(editData))
    }

    const SocialMediaImg = (event) => {
        const file = event?.target?.files[0];
        if(file) {
            const formData = new FormData();
            formData.append('image', file);
            dispatch(fetchSocialMedia(formData))
        }
    }
    return (
        <div className='w-full h-full'>
            <form onSubmit={handleSubmit}>
            <div class="w-full mt-3">
              <h4  className={"text-sm text-navy-700 dark:text-white ml-1.5 font-medium text-center w-full mb-3"}>Logo*</h4>

                {
                    socialMedia ? 
                        <label onChange={SocialMediaImg} for="dropzone-filesss" class="flex flex-col items-center justify-center w-full max-w-[300px] mx-auto h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                            <div class="flex flex-col items-center justify-center w-full h-full">
                                {
                                    <img src={socialMedia} alt="" className='w-full object-contain h-full' />
                                }
                            </div>
                            <input id="dropzone-filesss" type="file" class="hidden" accept="image/png, image/jpeg" />
                        </label>
                        :
                        <label for="dropzone-filesss" class="flex flex-col items-center justify-center w-full max-w-[300px] mx-auto h-32  border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                            <div class="flex flex-col items-center justify-center w-full h-full">
                                {
                                    <label for="dropzone-filesss" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                        <div class="flex flex-col items-center justify-center w-full h-full">
                                            <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400 text-center"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                                            <p class="text-xs text-gray-500 dark:text-gray-400 text-center">PNG or JPG</p>
                                        </div>
                                        <input id="dropzone-filesss" type="file" class="hidden" accept="image/png, image/jpeg" />
                                    </label>
                                }
                            </div>
                            <input id="dropzone-file" type="file" class="hidden" accept="image/png, image/jpeg" />
                        </label>
                }
            </div>
            <div className='grid grid-cols-2 gap-4 w-full mt-5'>
                <InputField
                    variant="auth"
                    extra="mb-3 w-full pr-2"
                    label="Name*"
                    placeholder="Enter Name .."
                    id="name"
                    type="text"
                    name="name"
                    value={editData?.name || ""}
                    onChange={handleChange}
                />
                <InputField
                    variant="auth"
                    extra="mb-3 w-full pr-2"
                    label="URL*"
                    placeholder="Enter URL.."
                    id="url"
                    type="text"
                    name="url"
                    value={editData?.url || ""}
                    onChange={handleChange}
                />
             
            </div>
            <div className="flex justify-end mt-7">
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" >
                    Update
                </button>
            </div>
            </form>
        </div>
    )
}

export default EditSocialMedia





