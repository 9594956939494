import BarChart from "components/charts/BarChart";
import { barChartDataDailyTraffic } from "variables/charts";
import { barChartOptionsDailyTraffic } from "variables/charts";
import Card from "components/card";
import { useDispatch, useSelector } from "react-redux";
import { fetchDailyTraffic } from "store/globalState";
import { useEffect, useState } from "react";
const DailyTraffic = () => {
  const dispatch = useDispatch();
  const dailyTraffic = useSelector((state) => state.global_state.dailyTraffic?.data);
  const [dailyTrafficOptions , setdailyTrafficOptions] = useState({})
  const [dailyTrafficCount , setDailyTrafficCount] = useState(null)
  const [dailyTrafficDate , setdailyTrafficDate] = useState([])
  const [dailyTrafficData , setdailyTrafficData] = useState([])
  useEffect(() => {
    dispatch(fetchDailyTraffic())
  }, [dispatch])
  useEffect(() => {
    if (dailyTraffic  && dailyTraffic.length > 0) {
      const date = dailyTraffic.map((val) => val?.date || "");
      setdailyTrafficDate(date);
      
      const data = dailyTraffic.map((val) => val?.traffic || 0);
      setdailyTrafficData(data);
    }
}, [dailyTraffic]);

useEffect(() => {
  setdailyTrafficOptions(
    {
      chart: {
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
          backgroundColor: "#000000"
        },
        onDatasetHover: {
          style: {
            fontSize: "12px",
            fontFamily: undefined,
          },
        },
        theme: "dark",
      },
      xaxis: {
        categories: dailyTrafficDate,
        show: false,
        labels: {
          show: true,
          style: {
            colors: "#A3AED0",
            fontSize: "14px",
            fontWeight: "500",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
        color: "black",
        labels: {
          show: true,
          style: {
            colors: "#CBD5E0",
            fontSize: "14px",
          },
        },
      },
      grid: {
        show: false,
        strokeDashArray: 5,
        yaxis: {
          lines: {
            show: true,
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          type: "vertical",
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          colorStops: [
            [
              {
                offset: 0,
                color: "#4318FF",
                opacity: 1,
              },
              {
                offset: 100,
                color: "rgba(67, 24, 255, 1)",
                opacity: 0.28,
              },
            ],
          ],
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: "40px",
        },
      },
    }
  )
  setDailyTrafficCount({
    name: "Daily Traffic",
    data: dailyTrafficCount,
  })
},[])
  return (
    <Card extra="pb-7 p-[20px]">
      <div className="flex flex-row justify-between">
        <div className="ml-1 pt-2">
          <p className="text-lg font-bold text-navy-700 dark:text-white">
            Daily Traffic
          </p>
        </div>
      </div>

      <div className="h-[300px] w-full pt-10 pb-0">
        {
          <BarChart
           chartData={barChartDataDailyTraffic}
           chartOptions={barChartOptionsDailyTraffic}
         />
        }
      
      </div>
    </Card>
  );
};

export default DailyTraffic;
