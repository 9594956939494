import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchOrderDetail } from 'store/globalState';
import OrderDetailTable from 'views/admin/tables/components/OrderDetailTable'
import { OrderListColData } from 'views/admin/tables/variables/columnsData'

function OrderDetail() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const orderDetailData = useSelector((state) => state.global_state.orderDetail?.order)
    const orderItemsData = useSelector((state) => state.global_state.orderDetail?.order?.items)
    useEffect(() => {
        dispatch(fetchOrderDetail(id))
    }, [dispatch, id])
    return (
        <div className="mt-[2.25rem] h-full w-full">
            <div className="grid gap-4 grid-cols-3">
                <div>
                    <label className="block mb-2 font-bold">First Name</label>
                    <p>{orderDetailData?.first_name}</p>
                </div>
                <div>
                    <label className="block mb-2 font-bold">Last Name</label>
                    <p>{orderDetailData?.last_name}</p>
                </div>
                <div>
                    <label className="block mb-2 font-bold">Company Name</label>
                    <p>{orderDetailData?.company_name}</p>
                </div>
                <div>
                    <label className="block mb-2 font-bold">Country</label>
                    <p>{orderDetailData?.country}</p>
                </div>
                <div>
                    <label className="block mb-2 font-bold">City</label>
                    <p>{orderDetailData?.city}</p>
                </div>
                <div>
                    <label className="block mb-2 font-bold">Street Address</label>
                    <p>{orderDetailData?.street_address}</p>
                </div>
                <div>
                    <label className="block mb-2 font-bold">Postal Code</label>
                    <p>{orderDetailData?.postal_code}</p>
                </div>
                <div>
                    <label className="block mb-2 font-bold">Email Address</label>
                    <p>{orderDetailData?.email_address}</p>
                </div>
                <div>
                    <label className="block mb-2 font-bold">Phone Number</label>
                    <p>{orderDetailData?.phone_number}</p>
                </div>
                {
                    orderDetailData?.isPaid === true ?
                        <div>
                            <label className="block mb-2 font-bold">Payment ID</label>
                            <p>{orderDetailData?.paymentId}</p>
                        </div> : null
                }

                {
                    orderDetailData?.isPaid === true ?
                    <div>
                        <label className="block mb-2 font-bold">Status</label>
                        <p className='px-2 py-1 bg-green-500 text-white font-semibold rounded'>Paid</p>
                    </div>
                    :
                    <div>
                        <label className="block mb-2 font-bold">Status</label>
                        <p className='px-2 py-1 bg-red-500 text-white font-semibold rounded'>Not Paid</p>
                    </div>
                }

                <div>
                    <label className="block mb-2 font-bold">Date</label>
                    <p>{orderDetailData?.createdAt}</p>
                </div>
                <div>
                </div>
            </div>
            <div className='mt-9'>
                <OrderDetailTable
                    columnsData={OrderListColData}
                    tableData={orderItemsData}
                />
            </div>
            <div className='grid justify-items-end mt-6'>
                <div>
                    <div className='grid grid-cols-2 gap-3'>
                        <label className="block mb-2 font-bold">Total Items :</label>
                        <p className='text-base font-bold text-[rgba(66,42,251,1)] text-center'>{orderDetailData?.total_items}</p>
                    </div>
                    <div className='grid grid-cols-2 gap-3'>
                        <label className="block mb-2 font-bold">Shipping Fee :</label>
                        <p className='text-base font-bold text-[rgba(66,42,251,1)] text-center'>{orderDetailData?.shipping_fee}</p>
                    </div>
                    <div className='grid grid-cols-2 gap-3'>
                        <label className="block mb-2 font-bold">SubTotal :</label>
                        <p className='text-base font-bold text-[rgba(66,42,251,1)] text-center'>{orderDetailData?.subtotal}</p>
                    </div>

                    <div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderDetail