
import Card from "components/card";
import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FiSearch } from "react-icons/fi";
import { fetchEditCoupon } from "store/globalState";
import { deleteCoupon } from "store/globalState";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { fetchSearchCoupon } from "store/globalState";


const CouponTable = (props) => {
    const coupon = useSelector((state) => state.global_state.coupon?.coupons)
    const { columnsData, tableData } = props;
    const navigate = useNavigate();
    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData || [], [tableData]);
    const dispatch = useDispatch();
    const tableInstance = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        initialState,
    } = tableInstance;
    initialState.pageSize = 5;
    const editCoupon = (id) => {
        navigate(`/admin/edit-coupon/${id}`);
        dispatch(fetchEditCoupon(id))
    }
    const createCoupon = (event) => {
    event.preventDefault();
    navigate('/admin/create-coupon')
  }

  const delCoupon = (id) => 
  {
    dispatch(deleteCoupon(id))
  }

  const debounce = (func, timeout = 500) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), timeout)
    }
  }
  const handleKeyDown = (event) => {
    const value = event.target.value;
    dispatch(fetchSearchCoupon(value))
  }

    return (
        <Card extra={"w-full h-full p-4 sm:overflow-x-auto"}>
            <div className="relative flex items-center justify-between">
                <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
                    <p className="pl-3 pr-2 text-xl">
                        <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                    </p>
                    <input
                        type="text"
                        placeholder="Search By Title..."
                        class="block h-12 w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                        onKeyDown={debounce(handleKeyDown)}
                    />
                </div>
                <div>
                    <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-4 rounded-md" onClick={createCoupon}>
                        Add Coupon
                    </button>
                </div>
            </div>
            <div class="mt-8 h-full overflow-x-scroll">
                <table {...getTableProps()} className="w-full">
                    <thead>
                        {headerGroups.map((headerGroup, index) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                {headerGroup.headers.map((column, index) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        key={index}
                                        className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                                    >
                                        <p className="text-xs tracking-wide text-gray-600">
                                            {column.render("Header")}
                                        </p>
                                    </th>
                                ))}
                                <th className="border-b border-gray-300 uppercase pb-[10px] text-start dark:!border-navy-700 "><div className="text-xs font-bold tracking-wide text-gray-600">
                                    Actions
                                </div></th>
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                               (!coupon || coupon.length === 0) ?
                               <tr>
                                 <td colSpan="1">
                                   <div className="w-full flex items-center justify-center h-full">
                                     <p className="text-center">No Products Found</p>
                                   </div>
                                 </td>
                               </tr>
               
                               :
                               page.map((row, index) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()} key={index}>
                                        {row.cells.map((cell, index) => {
                                            let data = "";
                                            if (cell.column.Header === "NAME") {
                                                data = (
                                                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                        {cell.value}
                                                    </p>
                                                );
                                            } else if (cell.column.Header === "CODE") {
                                                data = (
                                                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                        {cell.value}
                                                    </p>
                                                );
                                            } else if (cell.column.Header === "DISCOUNT") {
                                                data = (
                                                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                        {cell.value}
                                                    </p>
                                                );
                                            }
                                            else if (cell.column.Header === "STATUS") {
                                                data = (
                                                    <div className={`rounded-full text-xl`}>
                                                        {cell.value === true ? (
                                                            <span
                                                                className={"px-3 py-1 text-white text-sm  font-medium rounded bg-green-500"}
                                                            >
                                                               Active
                                                            </span>
                                                        ) : cell.value === false ? (
                                                            <span
                                                                className={"px-3 py-1 text-white text-sm  font-medium rounded bg-red-500"}
                                                            >
                                                               Expired
                                                            </span>
                                                        ) : null}
                                                    </div>
    
                                                );
                                            }
                                            else if (cell.column.Header === "START") {
                                                data = (
                                                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                        {cell.value}
                                                    </p>
                                                );
                                            }
                                            else if (cell.column.Header === "END") {
                                                data = (
                                                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                        {cell.value}
                                                    </p>
                                                );
                                            }
                                            else if (cell.column.Header === "USED") {
                                                data = (
                                                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                        {cell.value}
                                                    </p>
                                                );
                                            }
                                            return (
                                              
                                                <>
                                                <td
                                                  {...cell.getCellProps()}
                                                  key={index}
                                                  className="pt-[14px] pb-3 text-[14px] w-fit px-2 max-w-[200px]"
                                                >
                                                  {data}
                                                </td>
                                                {
                                                  row.cells.length - 1 === index ?
                                                    <td className="w-full flex items-center h-full">
                                                      <button className="bg-blue-500 hover:bg-blue-700 text-white font-medium py-1 px-2 text-sm rounded-md mr-2 mt-2" onClick={() => editCoupon(cell.row.original._id)}>
                                                        <BiEdit/>
                                                      </button>
                                                      <button className="bg-red-500 hover:bg-red-700 text-white font-medium py-1 px-2 text-sm rounded-md mt-2" onClick={() => delCoupon(cell.row.original._id)}>
                                                        <MdDelete/>
                                                      </button>
                                                    </td> : null
                        
                                                }
                                              </>
                                            );
                                        })}
    
                                    </tr>
                                );
                            })
                        }
                        
                    </tbody>
                </table>
            </div>
        </Card>
    );
};

export default CouponTable;
