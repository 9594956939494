import InputField from 'components/fields/InputField'
import React, { useEffect, useState } from 'react'
import { Editor } from "react-draft-wysiwyg"
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useDispatch } from 'react-redux';
import { updateContactUs } from 'store/globalState';

function ContactUs(props) {
  const data = props.data;
  const dispatch = useDispatch();
  const [contactUsData, setContactUsData] = useState(data?.contactus || "")
  const [description, setDescription] = useState(null)
  console.log("contactUsData", contactUsData)
  useEffect(() => {
    setContactUsData(data?.contactus || "");

    if(contactUsData) {
        setDescription(contactUsData?.text || "");
    }
}, [data]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    const names = name.split(".");
    if (names.length === 2) {
      setContactUsData(prevState => ({
        ...prevState,
        [names[0]]: {
          ...prevState[names[0]],
          [names[1]]: value
        }
      }));
    } else {
      setContactUsData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  }
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty() // Initialize with empty content
  );

  useEffect(() => {
    // Initialize with a predefined content
    if(contactUsData.text && editorState.getCurrentContent().hasText() === false)
    {
      const blocksFromHTML = convertFromHTML(contactUsData.text);
      const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(contentState));
    }


  }, [contactUsData]);

  const descStateChange = (editorState) => {
    const rawContent = convertToRaw(editorState.getCurrentContent());
    const updatedText = draftToHtml(rawContent);
    setEditorState(editorState);
    setContactUsData(prevData => ({
      ...prevData,
      text: updatedText
  }));
  };

  const updateContactData = () => {
   dispatch(updateContactUs(contactUsData))
  }

  return (
    <div>
      <div>
        <h2 className='text-center text-lg font-semibold mt-3 mb-5'>Monday to Thursday Timing</h2>
        <div className='flex gap-x-6 w-full'>
          <InputField
            variant="auth"
            extra="mb-3 w-49p"
            label="Opening Time*"
            placeholder="Enter Opening Time.."
            id="openingTime"
            type="text"
            name="mondayToThursday.openingTime"
            value={contactUsData?.mondayToThursday?.openingTime || ""}
            onChange={handleChange}
          />
          <InputField
            variant="auth"
            extra="mb-3 w-49p"
            label="Closing Time*"
            placeholder="Enter Closing Time.."
            id="closingTime"
            type="text"
            name="mondayToThursday.closingTime"
            value={contactUsData?.mondayToThursday?.closingTime || ""}
            onChange={handleChange}
          />
        </div>
      </div>
      <div>
        <h2 className='text-center text-lg font-semibold mt-3 mb-5'>Friday Timing</h2>
        <div className='flex gap-x-6 w-full'>
          <InputField
            variant="auth"
            extra="mb-3 w-49p"
            label="Morning Opening Time*"
            placeholder="Enter Morning Opening Time.."
            id="morningOpeningTime"
            type="text"
            name="friday.morningOpeningTime"
            value={contactUsData?.friday?.morningOpeningTime || ""}
            onChange={handleChange}
          />
          <InputField
            variant="auth"
            extra="mb-3 w-49p"
            label="Morning Closing Time*"
            placeholder="Enter Morning Closing Time.."
            id="morningClosingTime"
            type="text"
            name="friday.morningClosingTime"
            value={contactUsData?.friday?.morningClosingTime || ""}
            onChange={handleChange}
          />
        </div>
        <div className='flex gap-x-6 w-full'>
          <InputField
            variant="auth"
            extra="mb-3 w-49p"
            label="Afternoon Opening Time*"
            placeholder="Enter Afternoon Opening Time.."
            id="afternoonOpeningTime"
            type="text"
            name="friday.afternoonOpeningTime"
            value={contactUsData?.friday?.afternoonOpeningTime || ""}
            onChange={handleChange}
          />
          <InputField
            variant="auth"
            extra="mb-3 w-49p"
            label="Afternoon Closing Time*"
            placeholder="Enter Afternoon Closing Time.."
            id="afternoonClosingTime"
            type="text"
            name="friday.afternoonClosingTime"
            value={contactUsData?.friday?.afternoonClosingTime || ""}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-span-12 mt-7">
        <label
          htmlFor=""
          className={`text-sm text-navy-700 dark:text-white ml-1.5 font-medium block mb-3`}
        >
          Description*
        </label>
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="border border-gray-400 rounded-lg"
          editorClassName="px-3"
          onEditorStateChange={descStateChange}
          toolbar={{
            options: ['inline', 'link', 'list']
          }}
        />
      </div>
      <div className='mt-7'>
        <div className='flex gap-x-6 w-full'>
          <InputField
            variant="auth"
            extra="mb-3 w-49p"
            label="Address*"
            placeholder="Enter Address.."
            id="address"
            type="text"
            name="address"
            value={contactUsData?.address || ""}
            onChange={handleChange}
          />
          <InputField
            variant="auth"
            extra="mb-3 w-49p"
            label="Email*"
            placeholder="Enter Email.."
            id="email"
            type="text"
            name="email"
            value={contactUsData?.email || ""}
            onChange={handleChange}
          />
        </div>
        <div className='flex gap-x-6 w-full'>
          <InputField
            variant="auth"
            extra="mb-3 w-49p"
            label="Phone No*"
            placeholder="Enter Phone No.."
            id="phone"
            type="numnber"
            name="phone"
            value={contactUsData?.phone || ""}
            onChange={handleChange}
          />
          <InputField
            variant="auth"
            extra="mb-3 w-49p"
            label="Google Map link*"
            placeholder="Enter Google Map link.."
            id="Google Map link"
            type="text"
            name="Google Map link"
          // value={setCreateProdData.comparePrice}
          // onChange={handleChange}
          />

        </div>
      </div>
      <div className="flex justify-end mt-7">
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={updateContactData}>
          Update
        </button>
      </div>
    </div>
  )
}

export default ContactUs