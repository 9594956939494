import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MdOutlineCalendarToday,
} from "react-icons/md";
import Card from "components/card";
import LineChart from "components/charts/LineChart";
import { fetchTotalRevenue } from "store/globalState";

const TotalSpent = () => {
  const currentYear = new Date().getFullYear();
  const dispatch = useDispatch();
  const totalRevenue = useSelector((state) => state.global_state.totalRevenue?.data);
  const [totalRevenueData, setTotalRevenueData] = useState({});
  const [totalRData, settotalRData] = useState([]);
  const [monthData, setMonthData] = useState([]);
  const [monthRevenue, setMonthRevenue] = useState([]);
  const [monthProfit, setMonthProfit] = useState([]);
  useEffect(() => {
    if (totalRevenue && totalRevenue.length > 0) {
      const monthmap = totalRevenue.map((val) => val?.month || "");
      setMonthData(monthmap);
      
      const revenueMap = totalRevenue.map((val) => val?.totalRevenue || 0);
      setMonthRevenue(revenueMap);
      
      const profitMap = totalRevenue.map((val) => val?.totalProductsSold || 0);
      setMonthProfit(profitMap);
    }
}, [totalRevenue]);
  useEffect(() => {
    dispatch(fetchTotalRevenue(
      {
        year : currentYear.toString()
      }
    ))
  }, [dispatch])


  useEffect(() => {
    if (monthData.length > 0 && monthRevenue.length > 0 && monthProfit.length > 0) {
      setTotalRevenueData(
        {
          legend: {
            show: false,
          },
        
          theme: {
            mode: "light",
          },
          chart: {
            type: "line",
        
            toolbar: {
              show: false,
            },
          },
        
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
        
          tooltip: {
            style: {
              fontSize: "12px",
              fontFamily: undefined,
              backgroundColor: "#000000"
            },
            theme: 'dark',
            x: {
              format: "dd/MM/yy HH:mm",
            },
          },
          grid: {
            show: false,
          },
          xaxis: {
            
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              style: {
                colors: "#A3AED0",
                fontSize: "12px",
                fontWeight: "500",
              },
            },
            type: "text",
            range: undefined,
            categories: monthData,
          },
        
          yaxis: {
            show: false,
          },
        }
      )
      settotalRData(
        [
          {
            name: "Revenue",
            data: monthRevenue,
            color: "#4318FF",
          },
          {
            name: "Profit",
            data: monthProfit,
            color: "#6AD2FF",
          },
        ]
      )
    }

  }, [monthData,monthProfit,monthRevenue])

  return (
    <Card extra="!p-[20px] text-center">
      <div className="flex justify-between">
        <button className="linear mt-1 flex items-center justify-center gap-2 rounded-lg bg-lightPrimary p-2 text-gray-600 transition duration-200 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:hover:opacity-90 dark:active:opacity-80">
          <MdOutlineCalendarToday />
          <span className="text-sm font-medium text-gray-600">{currentYear}</span>
        </button>
        
      </div>

      <div className="flex h-full w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
        <div className="flex flex-col">
          <p className="mt-[20px] text-lg font-bold text-navy-700 dark:text-white">
          Revenue
          </p>

        </div>
        <div className="h-full w-full">
          {
            totalRevenueData &&  monthData.length > 0 && totalRData.length > 0 && <LineChart
            options={totalRevenueData}
            series={totalRData}
          />
          }
        
        </div>
      </div>
    </Card>
  );
};

export default TotalSpent;
