
import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { MdCheckCircle, MdCancel } from "react-icons/md";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { fetchOrderDetail } from "store/globalState";
import { useDispatch } from "react-redux";


const ComplexTable = (props) => {
  const { columnsData, tableData } = props;
  const navigate = useNavigate();
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData || [], [tableData]);
  const dispatch = useDispatch();
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 5;
  const orderDetail = (id) =>
  {
    navigate(`/admin/order-detail/${id}`);
    dispatch(fetchOrderDetail(id))
  }
  return (
    <Card extra={"w-full h-full p-4 sm:overflow-x-auto"}>

      <div class="mt-8 h-full overflow-x-scroll">
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                  >
                    <p className="text-xs tracking-wide text-gray-600">
                      {column.render("Header")}
                    </p>
                  </th>
                ))}
                <th className="border-b border-gray-300 uppercase pb-[10px] text-start dark:!border-navy-700 "><div className="text-xs font-bold tracking-wide text-gray-600">
                  Actions
                </div></th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "NAME") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "STATUS") {
                      data = (
                        <div className="flex items-center gap-2">
                          <div className={`rounded-full text-xl`}>
                            {cell.value === true ? (
                             <p className=" w-full flex gap-3 items-center text-base font-bold text-navy-700 dark:text-white"><MdCheckCircle className="text-green-500" /> Paid</p> 
                            ) : cell.value === false ? (
                             <p className="w-full flex gap-3 items-center text-base font-bold text-navy-700 dark:text-white"><MdCancel className="text-red-500" /> UnPaid</p> 
                            ) : null}
                          </div>
                         
                        </div>
                      );
                    } else if (cell.column.Header === "DATE") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    }
                    else if (cell.column.Header === "PHONE") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    }
                    else if (cell.column.Header === "ADDRESS") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    }
                    else if (cell.column.Header === "TOTAL PRICE") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    }
                    return (
                      <>
                      <td
                        className="pt-[14px] pb-[18px] sm:text-[14px]"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {data}
                      </td>
                      {
                        row.cells.length - 1 === index ?
                        <td>
                          <button class="bg-blue-500 hover:bg-blue-700 text-white font-medium py-1 px-4 text-sm rounded-md mr-2 mt-2" onClick={() => orderDetail(cell.row.original._id)}>Details</button>
                        </td> : null
                       
                      }
                     
                      </>
                    );
                  })}
                  
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default ComplexTable;
