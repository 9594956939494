import React, { useEffect, useState } from 'react'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { RiDeleteBinLine, RiEdit2Line } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from 'store/globalState';
import { Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { updateMainCate } from 'store/globalState';
import { updateSubCate } from 'store/globalState';
import Card from 'components/card';
import { postMainCategory } from 'store/globalState';


function Categories() {
  const dispatch = useDispatch();
  const categoriesData = useSelector((state) => state.global_state?.categories?.categories);
  const [categories, setCategories] = useState(categoriesData);

  const [mainCategoryUpdate, setMainCategoryUpdate] = useState(
    {
      name: '',
      id: ''
    }
  );
  const [createMainCategory, setCreateMainCategory] = useState(
    {
      name: '',
    }
  );
  const [subCategoryUpdate, setSubCategoryUpdate] = useState(
    {
      oldName: '',
      newName: ''
    }
  );
  useEffect(() => {
    dispatch(fetchCategories())
  }, [dispatch])
  const toggleSubcategories = (index) => {
    const updatedCategories = [...categories];
    const updatedCategory = { ...updatedCategories[index] };
    updatedCategory.category = updatedCategory.category.length === 0 ? categoriesData[index]?.category : [];
    updatedCategories[index] = updatedCategory;
    setCategories(updatedCategories);
  };
  const OverlayOne = () => (
    <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(90deg)'
    />
  )
  const { isOpen: isFirstModalOpen, onOpen: onFirstModalOpen, onClose: onFirstModalClose } = useDisclosure()
  const { isOpen: isSecondModalOpen, onOpen: onSecondModalOpen, onClose: onSecondModalClose } = useDisclosure()
  const { isOpen: isMCateModalOpen, onOpen: onMCateModalOpen, onClose: onMCateModalClose } = useDisclosure()
  const [overlay, setOverlay] = React.useState(<OverlayOne />)
  const mainCategoryChange = (event) => {
    setMainCategoryUpdate({
      ...mainCategoryUpdate,
      [event.target.name]: event.target.value
    })
  }
  const subCategoryChange = (event) => {
    setSubCategoryUpdate({
      ...subCategoryUpdate,
      newName : event.target.value
    })
  }
  const updateMainCategory = async () => {
    if(mainCategoryUpdate)
    {
      await dispatch(updateMainCate(mainCategoryUpdate))
      await Navigate()
      onFirstModalClose()
    }

  }
  const updateSubCategory = async () => {
   await dispatch(updateSubCate(subCategoryUpdate))
    await Navigate()
  }
  const Navigate = async () => {
    await dispatch(fetchCategories());
}
const createMainCategoryChange =(event) => {
  setCreateMainCategory(
    {...createMainCategory,
    [event.target.name] : event.target.value}
  )
}
const createMCategoryClick = () => {
dispatch(postMainCategory(createMainCategory))
}
  return (
    <>
    <Card>
      <div className='mt-[2.25rem] h-full w-full'>
      <div className="relative flex items-center justify-end mx-6">
                <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-4 rounded-md" onClick={() => {
                      setOverlay(<OverlayOne />)
                      onMCateModalOpen()
                    }}>
                    Add Main Category
                </button>
                </div>
        <table className="min-w-full bg-white">
       
          <thead>
            <tr>
              <th className="py-3 px-4 font-semibold text-left border-b">Main Category</th>
              <th className="py-3 px-4 font-semibold text-left border-b">Subcategories</th>
              <th className="py-3 px-4 font-semibold text-left border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {categories?.map((cate, index) => (
              <React.Fragment key={index}>
                <tr className="hover:bg-gray-100 cursor-pointer">
                  <td className="py-2 px-4 border-b">{cate?.parent_category}</td>
                  <td className="py-2 px-4 border-b">
                    <button
                      className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-3 rounded focus:outline-none"
                      onClick={() => toggleSubcategories(index)}
                    >
                      {cate.category.length > 0 ? <AiFillEye /> : <AiFillEyeInvisible />}
                    </button>
                  </td>
                  <td>
                    <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg mr-2" onClick={() => {
                      setOverlay(<OverlayOne />)
                      onFirstModalOpen()
                      setMainCategoryUpdate({
                        name: cate?.parent_category,
                        id: cate?._id
                      })
                    }} >
                      <RiEdit2Line />
                    </button>
                    <button className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-lg">
                      <RiDeleteBinLine />
                    </button>
                  </td>
                </tr>
                {cate?.category && (
                  <tr>
                    <td colSpan="3">
                      <table className="w-full">
                        <tbody>
                          {cate?.category?.map((subcategory) => (

                            <tr key={subcategory?._id} className="bg-gray-100">
                              <td className="py-2 px-4">{subcategory?.name}</td>

                              <td className="py-2 px-4">
                                <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-2 rounded-lg mr-2" onClick={
                                  () => {
                                    setOverlay(<OverlayOne />)
                                    onSecondModalOpen()
                                    setSubCategoryUpdate({
                                    oldName: subcategory?.name,
                                    newName: subcategory?.name
                                    })
                                  }
                                }>
                                  <RiEdit2Line />
                                </button>
                                <button className="bg-red-500 hover:bg-red-600 text-white py-1 px-2 rounded-lg">
                                  <RiDeleteBinLine />
                                </button>
                              </td>
                            </tr>

                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
        <Modal isCentered isOpen={isFirstModalOpen} onClose={onFirstModalClose}>
          {overlay}
          <ModalContent>
            <ModalHeader>Edit Parent Category</ModalHeader>
            <ModalCloseButton />
            <ModalBody>

              <FormControl mt={4}>
                <FormLabel>Parent Category</FormLabel>
                <Input placeholder='Parent Category' value={mainCategoryUpdate.name || ''} onChange={mainCategoryChange} name="name" />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button className="mx-3" onClick={updateMainCategory}>Update</Button>
              <Button onClick={onFirstModalClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>


        <Modal isCentered isOpen={isSecondModalOpen} onClose={onSecondModalClose}>
          {overlay}
          <ModalContent>
            <ModalHeader>Edit Sub Category</ModalHeader>
            <ModalCloseButton />
            <ModalBody>

              <FormControl mt={4}>
                <FormLabel>Sub Category</FormLabel>
                <Input placeholder='Sub Category' value={subCategoryUpdate.newName || ''} onChange={subCategoryChange} name="newName" />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button className="mx-3" onClick={updateSubCategory}>Update</Button>
              <Button onClick={onSecondModalClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isCentered isOpen={isMCateModalOpen} onClose={onMCateModalClose}>
          {overlay}
          <ModalContent>
            <ModalHeader>Create Main Category</ModalHeader>
            <ModalCloseButton />
            <ModalBody>

              <FormControl mt={4}>
                <FormLabel>Main Category</FormLabel>
                <Input placeholder='Main Category' value={createMainCategory.name || ''} onChange={createMainCategoryChange} name="name" />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button className="mx-3" onClick={createMCategoryClick}>Create</Button>
              <Button onClick={onMCateModalClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>

      </Card>
    </>
  )
}

export default Categories