import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import TotalSpent from "views/admin/default/components/TotalSpent";
import PieChartCard from "views/admin/default/components/PieChartCard";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";
import Widget from "components/widget/Widget";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchTotalEarning } from "store/globalState";
import { fetchPendingOrders } from "store/globalState";
import { fetchAvgDailySales } from "store/globalState";
import Card from "components/card";


const Dashboard = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const totEarning = useSelector((state) => state.global_state?.totalEarning);
  const pendOrders = useSelector((state) => state.global_state?.pendingOrders);
  const avgDailysales = useSelector((state) => state.global_state?.averageDailySales);
  const [totEarningData, setTotEarningData] = useState(
  {
    startDate : startDate,
    endDate: endDate
  });
  useEffect(() => {
    dispatch(fetchTotalEarning(totEarningData));
    dispatch(fetchPendingOrders());
    dispatch(fetchAvgDailySales());
  }, [dispatch])
  useEffect(() => {
    // Get current date
    const today = new Date();
    // Get date 6 months in the past
    const sixMonthsAgo = new Date(today);
    sixMonthsAgo.setMonth(today.getMonth() - 6);

    // Format dates to 'YYYY-MM-DD'
    const formatDate = (date) => {
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    };

    setEndDate(formatDate(today));
    setStartDate(formatDate(sixMonthsAgo));

  }, []);
  console.log("startDate", startDate)
  console.log("endDate", endDate)

  const getOrderData = () => {
    dispatch(fetchTotalEarning(totEarningData));
  }
  
  return (
    <div>

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
       <Widget
          icon={<MdDashboard className="h-6 w-6" />}
          title={"All Orders"}
          subtitle={"$1,000"}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Pending Orders"}
          subtitle={pendOrders?.totalPendingOrders}
        />
        <Widget
          icon={<IoDocuments className="h-7 w-7" />}
          title={"Average Daily Sales"}
          subtitle={"$" + avgDailysales?.sales}
        />

      </div>
      <div className=" mt-7 max-w-[800px] w-full">
      <Card extra="!flex-row flex-grow items-center rounded-[20px]">
        <div className="ml-[18px] flex h-[100px] w-auto flex-row items-center">
          <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
            <span className="flex items-center text-brand-500 dark:text-white">
              <MdBarChart className="h-6 w-6" />
            </span>
          </div>

          <div className="h-50 ml-4 flex w-auto flex-col justify-center">
            <p className="font-dm text-sm font-medium text-gray-600">Total Orders</p>
            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
              {totEarning.order}
            </h4>
          </div>

          {/* Start and End Date Selection */}
          <div className="ml-4">
            <label className="mr-2 text-sm">Start Date:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="text-sm p-1 border rounded mr-4"
            />
           

            <label className="mr-2 text-sm">End Date:</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="text-sm p-1 border rounded"

            />

            <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-4 mx-7 rounded-md" onClick={getOrderData}>
              Fetch Orders
            </button>
          </div>
        </div>
        </Card>
        </div>
      {/* Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <TotalSpent />

        <DailyTraffic />
      </div>

      {/* Tables & Charts */}

      <div className="mt-5 grid grid-cols-1 ">
        <PieChartCard />

      </div>
      
    </div>
  );
};

export default Dashboard;
