import React, { useEffect, useState } from 'react'
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useDispatch } from 'react-redux';
import { updateSafeShopping } from 'store/globalState';

function SafeShopping(props) {
    const data = props.data;
    const dispatch = useDispatch()
    const [safeShopping, setSafeShopping] = useState(data?.safeShopping || "")
    const [description, setDescription] = useState(null)
    useEffect(() => {
      if (data) {
          setDescription(data?.safeShopping?.text || "");
      }
  }, [data]);

  useEffect(() => {
      setSafeShopping(data?.safeShopping || "");
  }, [data]);
    const [editorState, setEditorState] = useState(
        EditorState.createEmpty() // Initialize with empty content
      );
    
      useEffect(() => {
        // Initialize with a predefined content
        if(safeShopping?.text && editorState.getCurrentContent().hasText() === false)
        {
          const blocksFromHTML = convertFromHTML(safeShopping?.text);
          const contentState = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap
          );
          setEditorState(EditorState.createWithContent(contentState));
        }
    
    
      }, [description]);
    
      const descStateChange = (editorState) => {
        const rawContent = convertToRaw(editorState.getCurrentContent());
      const updatedText = draftToHtml(rawContent);
      setEditorState(editorState);
      setSafeShopping(prevData => ({
        ...prevData,
        text: updatedText
    }));
      };

      const updateSafeShoppingData = () => {
        dispatch(updateSafeShopping(safeShopping))
      }
  return (
    <div>
    <div className="col-span-12 mt-7">
      <label
        htmlFor=""
        className={`text-sm text-navy-700 dark:text-white ml-1.5 font-medium block mb-3`}
      >
        Description*
      </label>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="border border-gray-400 rounded-lg"
        editorClassName="px-3"
        onEditorStateChange={descStateChange}
        toolbar={{
          options: ['inline', 'link', 'list']
        }}
      />
    </div>
    <div className="flex justify-end mt-7">
<button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={updateSafeShoppingData}>
Update
</button>
</div>
</div>
  )
}

export default SafeShopping