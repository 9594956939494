import InputField from 'components/fields/InputField'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import { updateCoupon } from 'store/globalState';
import { fetchEditCoupon } from 'store/globalState';

function EditCoupon() {
    const { id } = useParams();
    const couponData = useSelector((state) => state.global_state.editCoupon?.coupon);
    const dispatch = useDispatch();
    const [editData , setEditData] = useState(couponData || "")
    const datTime = editData?.expirationTime?.split(".")[0]?.replace(/[/]/g, '-')
    useEffect(() => {
        dispatch(fetchEditCoupon(id))
    },[dispatch, id])
    const handleChange = (event) => {
        setEditData({
            ...editData,
            [event.target.name] : event.target.value
        })
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            id : id,
            formData : editData
        }
        dispatch(updateCoupon(data))
    }
  return (
    <div className="mt-[2.25rem] h-full w-full">
            <form action="" onSubmit={handleSubmit}>
                <div className='grid grid-cols-2 gap-4 w-full'>
                    <InputField
                        variant="auth"
                        extra="mb-3 w-full pr-2"
                        label="Name*"
                        placeholder="Enter Name.."
                        id="name"
                        type="text"
                        name="name"
                        value={editData?.name || ""}
                        onChange={handleChange}
                    />
                    <InputField
                        variant="auth"
                        extra="mb-3 w-full pr-2"
                        label="Code*"
                        placeholder="Enter Code.."
                        id="code"
                        type="text"
                        name="code"
                        value={editData?.code || ""}
                        onChange={handleChange}
                    />
                </div>
                <div className='grid grid-cols-2 gap-4 w-full'>
                    <InputField
                        variant="auth"
                        extra="mb-3 w-full pr-2"
                        label="Discount*"
                        placeholder="Enter Discount.."
                        id="discount"
                        type="text"
                        name="discount"
                        value={editData?.discount || ""}
                        onChange={handleChange}

                    />
                    <InputField
                        variant="auth"
                        extra="mb-3 w-full pr-2"
                        label="Expiration Time*"
                        placeholder="Enter Expiration Time.."
                        id="expirationTime"
                        type="datetime-local"
                        name="expirationTime"
                        value={datTime}
                        onChange={handleChange}
                    />
                </div>
                <div class="grid grid-cols-1 mt-5 mx-3">
                    <div class="col-start-12 col-span-2">
                        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type='submit'>
                            Update
                        </button>
                    </div>
                </div>
            </form>


        </div>
  )
}

export default EditCoupon