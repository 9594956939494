
import Card from "components/card";
import { FiSearch } from "react-icons/fi";

import React, { useEffect, useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductEdit } from "store/globalState";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { deleteProd } from "store/globalState";
import { fetchProductList } from "store/globalState";
import { fetchSearchProducts } from "store/globalState";
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";

const DevelopmentTable = (props) => {
  const { columnsData, tableData } = props;
  const productListData = useSelector((state) => state.global_state.productList?.products)
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData || [], [tableData]);
  const [deleteID, setDeleteID] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 11;
  useEffect(() => {
    dispatch(fetchProductList());
  }, [dispatch])
  const createProduct = (event) => {
    event.preventDefault();
    navigate('/admin/create-product')
  }

  const editProduct = (id) => {
    navigate(`/admin/edit-product/${id}`)
    dispatch(fetchProductEdit(id))

  }
  const deleteProduct = async (id) => {
    if (deleteID) {
     await dispatch(deleteProd(id))
     await fetchUpdatedProductList();
      onClose();
    }
  }

  const fetchUpdatedProductList = async () => {
    await dispatch(fetchProductList());
  };

  const debounce = (func, timeout = 500) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), timeout)
    }
  }
  const handleKeyDown = (event) => {
    const value = event.target.value;
    dispatch(fetchSearchProducts(value))
  }

  const OverlayOne = () => (
    <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(90deg)'
    />
  )
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [overlay, setOverlay] = React.useState(<OverlayOne />)
  return (
    <>
      <Card extra={"w-full h-full p-4 sm:overflow-x-auto"}>
        <div class="relative flex items-center justify-between">
          <div className="flex h-11 items-center rounded-full  bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
            <p className="pl-3 pr-2 text-xl">
              <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
            </p>
            <input
              type="text"
              placeholder="Search By Title..."
              class="block h-12 w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
              onKeyDown={debounce(handleKeyDown)}
            />
          </div>
          <div>
            <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-4 rounded-md" onClick={createProduct}>
              Create
            </button>
          </div>
        </div>

        <div class="h-full overflow-x-auto">
          <table
            {...getTableProps()}
            className="mt-8 h-max w-full"
            variant="simple"
            color="gray-500"
            mb="24px"
          >
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="border-b border-gray-300 uppercase pb-[10px] text-start dark:!border-navy-700 px-2"
                      key={index}
                    >
                      <div className="text-xs font-bold tracking-wide text-gray-600">
                        {column.render("Header")}
                      </div>
                    </th>
                  ))}
                  <th className="border-b border-gray-300 uppercase pb-[10px] text-start dark:!border-navy-700 "><div className="text-xs font-bold tracking-wide text-gray-600">
                    Actions
                  </div></th>
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {

                (!productListData || productListData.length === 0) ?
                  <tr>
                    <td colSpan="1">
                      <div className="w-full flex items-center justify-center h-full">
                        <p className="text-center">No Products Found</p>
                      </div>
                    </td>
                  </tr>

                  :
                  page.map((row, index) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} key={index}>
                        {row.cells.map((cell, index) => {
                          let data = "";

                          if (cell.column.Header === "Image") {
                            data = (
                              cell?.value.startsWith("https") ?
                                <img src={cell?.value} alt="" className="w-28 h-28 object-contain" />
                                :
                                <img src={cell?.value} alt="" className="w-28 h-28 object-contain" crossOrigin="anonymous" />
                            );
                          } else if (cell.column.Header === "Title") {
                            data = (
                              <div className="flex items-center gap-3">
                                <p><p className="text-sm font-bold text-navy-700 dark:text-white">
                                  {cell.value}
                                </p></p>
                              </div>
                            );
                          } else if (cell.column.Header === "MAIN CATEGORY") {
                            data = (
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {cell.value}
                              </p>
                            );
                          } else if (cell.column.Header === "SUB CATEGORY") {
                            data = (
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {cell.value}
                              </p>
                            );
                          } else if (cell.column.Header === "Price") {
                            data = (
                              <div className="flex items-center gap-3">
                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                  {cell.value}
                                </p>
                              </div>
                            );
                          }
                          else if (cell.column.Header === "Discount Price") {
                            data = (
                              <div className="flex items-center gap-3">
                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                  {cell.value}
                                </p>
                              </div>
                            );
                          }
                          else if (cell.column.Header === "Stock") {
                            data = (
                              <div className="flex items-center gap-3">
                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                  {cell.value}
                                </p>
                              </div>
                            );
                          }
                          return (
                            <>
                              <td
                                {...cell.getCellProps()}
                                key={index}
                                className="pt-[14px] pb-3 text-[14px] w-fit px-2 max-w-[200px]"
                              >
                                {data}
                              </td>
                              {
                                row.cells.length - 1 === index ?
                                  <td className="w-full flex items-center h-full">
                                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-medium py-1 px-2 text-sm rounded-md mr-2 mt-2" onClick={() => editProduct(cell.row.original._id)}>
                                      <BiEdit />
                                    </button>
                                    <button className="bg-red-500 hover:bg-red-700 text-white font-medium py-1 px-2 text-sm rounded-md mt-2" onClick={() => {
                                      setOverlay(<OverlayOne />)
                                      onOpen()
                                      setDeleteID(cell.row.original._id)
                                    }}>
                                      <MdDelete />
                                    </button>
                                  </td> : null

                              }
                            </>
                          );
                        })}

                      </tr>
                    );
                  })}

            </tbody>
          </table>

        </div>
      </Card>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        {overlay}
        <ModalContent>
          <ModalHeader>Delete a Product</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to delete this prdouct</Text>
          </ModalBody>
          <ModalFooter>
            <Button className="mx-3" onClick={() => deleteProduct(deleteID)} >Yes</Button>
            <Button onClick={onClose}>No</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DevelopmentTable;
